// angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { Utils } from '../../utils/utils';
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Animation, AnimationController, NavController, IonModal } from '@ionic/angular';

// constants
import { Constants } from '../../app.constants';

// libraries
import { TranslateService } from '@ngx-translate/core';

import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { IonInput, Platform } from '@ionic/angular';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup, ValidationErrors, ValidatorFn,
    Validators
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IGroup } from '../../interfaces/IGroup';
import { GroupsApi } from '../../services/api/groups-api.service';
import { UiUtils } from '../../services/ui-utils.service';
import { SharedUiService } from 'src/app/services/shared-ui.service';
import { UsersApi } from 'src/app/services/api/users-api.service';

@Component({
    selector: 'class-create-new-page',
    templateUrl: 'class-create-new-page.html',
    styleUrls: ['class-create-new-page.scss']
})
export class ClassCreateNewPage extends AbstractRootMenuPage implements OnInit {
    @ViewChild('nameInput', { static: false }) nameInput!: IonInput;
    @ViewChild('descriptionInput', { static: false }) descriptionInput!: IonInput;
    @ViewChild(IonModal) modal: IonModal;

    public systemLanguageName: string;
    @ViewChild('swiper')
        swiperRef: ElementRef | undefined;
    pagination = {
        clickable: true,
        type: 'progressbar'
    };
    underage = false;
    @ViewChild('inputStartDatePicker', { static: false }) inputStartDatePicker: IonInput;
    @ViewChild('inputEndDatePicker', { static: false }) inputEndDatePicker: IonInput;
    // emailsInvite = []
    // emailsInvite = []
    public showCustomDatePicker = true;
    inviteForm: FormGroup;
    creatingClass: boolean;
    invitingTeachers: boolean;
    allSetUp: boolean;
    showCreatingClass = true;

    public nameForm: UntypedFormGroup;
    public nameLastNameForm: UntypedFormGroup;
    public descriptionForm: UntypedFormGroup;
    public dateForm: UntypedFormGroup;
    public languageForm: UntypedFormGroup;
    public validationMessages = null;
    public disableNext = false;
    public endDateMin: string;
    dateFormat: 'DD MMM YYYY';
    public minDate: Date = new Date();
    public maxDate: Date = new Date();
    public minString: string;
    public maxString: string;
    showNameLastNameStep = true;
    activeIndex = 0;


    constructor(
        private groupsApi: GroupsApi,
        private animationCtrl: AnimationController,
        public appData: AppData,
        public navCtrl: NavController,
        private uiUtils: UiUtils,
        public constants: Constants,
        public translate: TranslateService,
        public analytics: AnalyticsService,
        private titleService: Title,
        private formBuilder: UntypedFormBuilder,
        private fb: FormBuilder,
        private sharedUiService: SharedUiService,
        public userApi: UsersApi,
        protected plt: Platform) {
        super(plt);
    }

    public updateEndDateMin(): void {
        // Get the value of the start date control
        const startDateValue = this.dateForm.get('startDate').value;
        // Update the minimum date of the end date control to be the day after the start date
        const endDateMinDate = new Date(startDateValue);
        endDateMinDate.setDate(endDateMinDate.getDate() + 1);
        this.endDateMin = endDateMinDate.toISOString();
        this.dateForm.get('endDate').setValue(this.endDateMin);
    }

    public setEndDateMax() {
        const startDateValue = this.dateForm.get('startDate').value;
        const today = new Date(startDateValue);
        today.setFullYear(today.getFullYear() + 2);
        return today.toISOString();
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'onboarding';
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    async ionViewDidEnter() {
        // Show onboarding tutorial
        const tutorialDone = await this.userApi.getPreferences().toPromise();
        if (!tutorialDone.tutorial_done_educators_scooling_welcome) {
            this.sharedUiService.showEducatorTutorialPage(true);
        }
    }

    get emails(): FormArray {
        return this.inviteForm.get('emails') as FormArray;
    }

    // get emails(): FormArray {
    //     return this.inviteForm.get('emails') as FormArray;
    // }
    inviteTeachers = false;
    loading = false;
    isNameStepSubmitted = false;
    addEmail() {
        if (this.emails.length < 5) {
            this.emails.push(this.fb.control(null, [Validators.required, Validators.email]));
        }
    }

    removeEmail(index: number) {
        if (this.emails.length > 2) {
            this.emails.removeAt(index);
        }
    }

    submitInvite() {
        if (this.inviteForm.valid) {
            // Handle submission of form and sending of invitations
        } else {
            this.inviteForm.markAllAsTouched();
        }
    }

    get errorControlForName() {
        return this.nameForm.controls;
    }

    shouldApplyNoOverflowClass(step: number): boolean {
        return [0, 1, 2, 4].includes(step);
    }
    onKeyPressSwiper($event) {
        $event.preventDefault();
        console.log('recibe ', $event);
        this.previous();
    }


    validateEndDate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (!control || !control.parent) {
                return null;
            }

            const startDateValue = new Date(this.dateForm.get('startDate').value).getTime();
            const endDateValue = new Date(control.value).getTime();

            return endDateValue >= startDateValue ? null : { 'dateError': true };
        };
    }

    async ngOnInit() {
        this.titleService.setTitle('Onboarding | uugot.it');

        const swiperEl = document.querySelector('swiper-container');
        this.minDate.setMonth(this.minDate.getMonth() - 6);
        this.maxDate.setFullYear(this.maxDate.getFullYear() + 3);
        this.minString = this.minDate.toISOString().substr(0, 10);
        this.maxString = this.maxDate.toISOString().substr(0, 10);
        if (this.appData.authenticatedUser.first_name && this.appData.authenticatedUser.last_name) {
            this.showNameLastNameStep = false;
        }

        this.disableNext = true;


        this.nameForm = this.formBuilder.group({
            className: [
                '', {
                    validators: [Validators.required, Validators.minLength(2), Validators.maxLength(20)],
                    updateOn: 'change'
                }
            ]
        });


        this.nameForm.valueChanges.subscribe(() => {
            this.disableNext = this.nameForm.invalid;
        });


        this.descriptionForm = this.formBuilder.group({
            description: ['', [Validators.maxLength(100)]],
        });


        this.descriptionForm.valueChanges.subscribe(() => {
            this.disableNext = this.descriptionForm.invalid;
        });


        this.languageForm = this.formBuilder.group({
            language: ['', [Validators.required]],
        });

        this.dateForm = this.formBuilder.group({
            startDate: [new Date().toISOString(), [Validators.required]],
            // endDate: [new Date().toISOString(), [Validators.required]]
            endDate: [new Date().toISOString(), [Validators.required, this.validateEndDate()]]
        });
        this.updateEndDateMin();
        this.dateForm.valueChanges.subscribe(() => {
            this.disableNext = this.dateForm.invalid;
        });

        this.inviteForm = this.fb.group({
            emails: this.fb.array([[null, [Validators.required, Validators.email]],]),
        });
        // this.emailsInvite = this.inviteForm.get('emails').value;
        // this.a   nalytics.trackPageView('onboarding-page');
        this.systemLanguageName = this.appData.getLanguage();

        this.translate
            .get(['validator_generic_required', 'validator_generic_minLength', 'validator_generic_maxLength'])
            .subscribe((i18n) => {
                this.validationMessages = {
                    className: [
                        { type: 'required', message: i18n['validator_generic_required'] },
                        { type: 'minlength', message: i18n['validator_generic_minLength'].replace('%d', 2) },
                        { type: 'maxlength', message: i18n['validator_generic_maxLength'].replace('%d', 40) },
                    ],
                    description: [
                        { type: 'required', message: i18n['validator_generic_required'] },
                        { type: 'maxlength', message: i18n['validator_generic_maxLength'].replace('%d', 200) },
                    ],
                    language: [{ type: 'required', message: i18n['validator_generic_required'] }],
                    startDate: [{ type: 'required', message: i18n['validator_generic_required'] }],
                    endDate: [{ type: 'required', message: i18n['validator_generic_required'] }]
                };
            });


    }

    setFocus() {
        this.activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex;
        switch (this.activeIndex) {
            case 0: // ClassNameStep
                this.nameInput.setFocus();
                break;
            case 1: // DescriptionStep
                this.descriptionInput.setFocus();
                break;
            // Add more cases as needed for other steps
            default:
                break;
        }
    }

    async next() {
        let activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex;
        const isClassNameStep = activeIndex === 0; // Adjusted index
        const isDescriptionStep = activeIndex === 1; // Adjusted index
        const isDateStep = activeIndex === 2; // Adjusted index

        if (isClassNameStep && !this.nameForm.valid) {
            return;
        }

        if (isDescriptionStep && !this.descriptionForm.valid) {
            return;
        }

        if (isDateStep && !this.dateForm.valid) {
            return;
        }
        //
        this.swiperRef?.nativeElement.swiper.slideNext();
        activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex;
        const loadingSlide = activeIndex === 4; // Adjusted index
        if (loadingSlide) {
            await this.finishOnboarding();
        }
    }

    async finishOnboarding() {
        const creatingClassMessage = this.animationCtrl.create()
            .addElement(document.querySelector('.creatingClass'))
            .duration(1500)
            .fromTo('transform', 'translateX(0px)', 'translateX(80px)')
            .fromTo('opacity', '1', '0');

        const settingEverythingUpMessage = this.animationCtrl.create()
            .addElement(document.querySelector('.settingEverythingUp'))
            .duration(1500)
            .fromTo('transform', 'translateX(0px)', 'translateX(80px)')
            .fromTo('opacity', '1', '0');


        this.loading = true;
        try {
            const result = await this.createClass();
            console.log('Success - post group by user id', result);
            this.appData.groupsForUser.push(result.data);
            this.uiUtils.displayToast(this.translate.instant('class_created'));
            this.appData.activePage = '';
            this.appData.activeGroupId = result.data._id;
            this.loading = false;
            this.appData.savePreferenceString(this.constants.pref.ONBOARDING_EDUCATOR_DONE, '1');
            this.navCtrl.navigateForward(`/educator-class/details/${result.data._id}`);
        } catch (err) {
            console.log('Error - post group by user id', err);
            this.loading = false;
            switch (err.status) {
                case 400:
                    this.previous();
                    this.uiUtils.showErrorAlert(this.translate.instant('invalid_input'));
                    break;
                case 404:
                    this.uiUtils.showErrorAlert(this.translate.instant('user_not_found'));
                    break;
                case 409:
                    this.uiUtils.showErrorAlert(this.translate.instant('class_exists'));
                    break;
            }
        }

        await creatingClassMessage.play();
        await settingEverythingUpMessage.play();
    }

    previous() {
        this.swiperRef?.nativeElement.swiper.slidePrev();
    }


    disablePreviousButton() {
        return this.swiperRef?.nativeElement.swiper.activeIndex <= 0;
    }

    getTextForNextButton() {
        if (this.showNameLastNameStep && this.swiperRef?.nativeElement.swiper.activeIndex === 1) {
            return 'Start';
        }
        if (this.swiperRef?.nativeElement.swiper.activeIndex === 7 || this.swiperRef?.nativeElement.swiper.activeIndex === 6 && !this.inviteTeachers) {
            return 'Finish and create';
        }
        return this.translate.instant('next');
    }

    createClass() {
        const newClass: IGroup = {
            name: this.nameForm.value.className,
            nameFromOnboarding: this.nameLastNameForm?.value?.name || this.appData.authenticatedUser.first_name,
            lastNameFromOnboarding: this.nameLastNameForm?.value?.lastName || this.appData.authenticatedUser.last_name,
            description: this.descriptionForm.value.description,
            base_language: 'de',
            // base_language: this.languageForm.value.language,
            start: new Date(this.dateForm.value.startDate),
            end: new Date(this.dateForm.value.endDate),
            educator: null,
            under14_students: this.underage,
            // educators test
            access_code: this.appData.authenticatedUser.access_code,
            customer_id: undefined,
            team_educators: [
                {
                    edId: this.appData.authenticatedUser._id,
                    subjects: []
                }
            ],
        };

        return this.groupsApi.createGroup(newClass).toPromise();
    }

    disabledNextButton() {
        // switch (this.swiperRef?.nativeElement.swiper.activeIndex) {
        //     case :
        //         return true;
        //     default:
        //         return false;
        // }
    }

    getUserName() {
        if (this.appData.authenticatedUser.first_name) {
            return this.appData.authenticatedUser.first_name;
        }
        return this.nameLastNameForm.value.name;
    }

    setNotUnderage() {
        this.underage = false;
        this.isModalOpen = false;
    }

    isModalOpen = false;

    /**
     * Opens a modal to show more information
     */
    async openMoreModal() {
        this.isModalOpen = true;
    }

    hideMoreModal() {
        this.isModalOpen = false;
    }
}
