<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title *ngIf="showNameLastNameStep && swiperRef?.nativeElement.swiper.activeIndex == 0">{{ "welcome_create_first_class" | translate }}</ion-title>
        <ion-title *ngIf="!showNameLastNameStep || (showNameLastNameStep && swiperRef?.nativeElement.swiper.activeIndex != 0)">{{ "create_first_class" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
    <swiper-container
        (slidechangetransitionend)="setFocus()"
        [pagination]='pagination'
        space-between="30"
        [allowTouchMove]='false'
        class=''
        #swiper >
<!--        step 0-->
        <swiper-slide  class="ion-padding" *ngIf='showNameLastNameStep'>
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img src="assets/img/yuyu_yellow_exclamation_sign.png"></ion-img>
                        <!--            <img src="../../assets/slide1.png" >-->
                        <h2>{{ "salute" | translate }}<br> {{ "name_question" | translate }}</h2>
                        <form [formGroup]='nameLastNameForm' novalidate>
                            <ion-item>
                                <ion-label position="floating">{{ "name" | translate }}</ion-label>
                                <ion-input
                                    (ionFocus)='$event.preventDefault();'
                                    formControlName="name"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">{{ "lastname" | translate }}</ion-label>
                                <ion-input  formControlName="lastName"></ion-input>
                            </ion-item>
                        </form>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <!--        step 1-->
        <swiper-slide class="ion-padding">
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img  *ngIf='systemLanguageName ==="de"'  class='ion-margin-top' src="assets/img/tutorials/S01_de.jpg"></ion-img>
                        <ion-img *ngIf='systemLanguageName !== "de"'  class='ion-margin-top' style='height: 420px' src="assets/img/tutorials/S01_en.png"></ion-img>
                        <h2>Hey  {{ getUserName() }}, {{ "create_first_class_lower" | translate }}</h2>
                        <ion-button color="primary" (click)='next()'>{{ "get_started" | translate }}</ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <!--        step 2-->
        <swiper-slide class="ion-padding">
            <ion-row class=' ion-justify-content-center centered-row'>
                <ion-col>
                    <div class='slide-desktop-container'>
                    <h2>{{ "add_name" | translate }}</h2>
                    <form [formGroup]='nameForm' class='ion-padding' novalidate>
                        <ion-item class=''>
                            <ion-label position="floating">{{ "class_name" | translate }}</ion-label>
                            <ion-input
                                #nameInput
                                formControlName="className"
                                type="text"
                                style="width: 100%;">
                            </ion-input>
                        </ion-item>
                        <ion-label
                            *ngIf="nameForm.controls.className.invalid && (nameForm.controls['className'].dirty)">
                            <p class="error-color" *ngIf="nameForm.controls.className.errors?.required">{{ "class_name_required_hint" | translate }}</p>
                            <p class="error-color" *ngIf="nameForm.controls.className.errors?.maxlength">{{ "class_name_length_hint" | translate }}</p>
                        </ion-label>
                    </form>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <!--        step 3-->
        <swiper-slide class="ion-padding">
            <ion-row class='ion-justify-content-center centered-row'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <h2>{{ "description_class" | translate }}</h2>
                        <form [formGroup]='descriptionForm' class='ion-padding'>
                            <ion-item class=''>
                                <ion-label position="floating">{{ "class_description" | translate }}</ion-label>
                                <ion-input
                                        #descriptionInput
                                        formControlName="description">
                                    </ion-input>
                                <ion-note slot="helper">{{ "optional" | translate }}</ion-note>
                            </ion-item>
                        </form>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <!--        step 4-->
        <swiper-slide class='ion-padding'>
            <ion-row class='ion-justify-content-center centered-row'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <h2>{{ "select_class_date" | translate }}</h2>
                        <ion-list>
                            <!-- START DATE: -->
                            <form [formGroup]='dateForm'>
                                <ion-item class="ion-no-padding">
                                    <ion-label >
                                        {{"start_date" | translate }} <sup>*</sup>
                                    </ion-label>
                                    <ion-datetime-button datetime="start_date">
                                    </ion-datetime-button>
                                    <ion-modal [keepContentsMounted]="true">
                                        <ng-template>
                                            <ion-datetime
                                                [showDefaultButtons]="true"
                                                id="start_date"
                                                (ionChange)="updateEndDateMin()"
                                                formControlName="startDate"
                                                [max]="setEndDateMax()"
                                                presentation='date'>
                                            </ion-datetime>
                                        </ng-template>
                                    </ion-modal>

                                </ion-item>

                                <!-- END DATE: -->
                                <ion-item class="ion-no-padding">
                                    <ion-label >
                                        {{"end_date" | translate }} <sup>*</sup>
                                    </ion-label>

                                    <ion-datetime-button datetime="end_date"></ion-datetime-button>
                                    <ion-modal [keepContentsMounted]="true">
                                        <ng-template>
                                            <ion-datetime
                                                [min]="endDateMin"
                                                [max]="setEndDateMax()"
                                                [showDefaultButtons]="true"
                                                formControlName="endDate"
                                                id="end_date"
                                                presentation='date'>
                                            </ion-datetime>
                                        </ng-template>
                                    </ion-modal>
                                </ion-item>
                                <ion-label class="validator-errors" color="danger" *ngIf="dateForm && dateForm.controls.endDate.invalid && (dateForm.controls['endDate'].dirty)">
                                    <p *ngIf="dateForm.controls.endDate.errors?.dateError"
                                    >
                                    </p>
                                </ion-label>
                            </form>
                        </ion-list>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <!--        step 5-->
        <swiper-slide class="swiper-slide-scrollable">
            <!--            <img src="../../assets/slide3.png" >-->
            <ion-row class='ion-justify-content-center centered-row'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <h2 >{{ "verify_youngest_pupil" | translate }}</h2>
                        <ion-list lines='none'>
                            <ion-item button="true" (click)='underage=true'>
                                <ion-card [ngClass]="{'ion-card-item': true, 'active-card': underage}">
                                    <ion-card-header>
                                        <ion-card-title
                                            [ngClass]="{'active-card-title': underage, 'ion-text-center': true}"
                                        >{{ "pupils_14" | translate }}</ion-card-title>
                                    </ion-card-header>
                                    <ion-card-content class="ion-text-center">
                                        <ion-text>{{ "kids_content_available" | translate }}</ion-text>
                                    </ion-card-content>
                                </ion-card>
                            </ion-item>
                            <ion-item button="true" class='' (click)='setNotUnderage()'>
                                <ion-card [ngClass]="{'ion-card-item': true, 'active-card': !underage}">
                                    <ion-card-header>
                                        <ion-card-title  [ngClass]="{'active-card-title': !underage, 'ion-text-center': true}">{{ "all_pupils_over_14" | translate }}</ion-card-title>
                                    </ion-card-header>
                                    <ion-card-content class="ion-text-center">
                                        <ion-text>{{ "all_content" | translate }}</ion-text>
                                    </ion-card-content>
                                </ion-card>
                            </ion-item>
                        </ion-list>
                        <ion-card *ngIf="underage" style="box-shadow: none !important;">
                            <ion-card-content>
                                <img alt="Question button" src="/assets/img/info_button_question_icon.png" />
                                <ion-text>
                                    {{"pupils_14_info" | translate }}
                                    <span id="open-modal" expand="block" (click)="openMoreModal()" class="more-modal">{{ "pupils_14_info_more" | translate }}</span>
                                </ion-text>
                            </ion-card-content>
                        </ion-card>


                        <ion-modal trigger="open-modal" [isOpen]="isModalOpen">
                            <ng-template>
                                <ion-header>
                                    <ion-toolbar>
                                        <ion-buttons slot="end">
                                            <ion-button (click)="hideMoreModal()">{{ "modal_btn_cancel" | translate }}</ion-button>
                                        </ion-buttons>
                                        <ion-title>{{ "pupils_14_info_more" | translate }}</ion-title>
                                    </ion-toolbar>
                                </ion-header>
                                <ion-content class="ion-padding">
                                    <ion-label position="stacked">{{ "pupils_14_modal" | translate }}</ion-label>
                                </ion-content>
                            </ng-template>
                        </ion-modal>
                    </div>

                </ion-col>
            </ion-row>

        </swiper-slide>
        <!--        step 6-->
        <swiper-slide class="ion-padding">
            <ion-row class='ion-justify-content-center centered-row'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-spinner></ion-spinner>
                        <div class="ion-margin-top creatingClass" >
                            <h2>{{ "creating_class" | translate }}</h2>
                        </div>
                        <div class="settingEverythingUp"  >
                            <h2>{{ "setting_students" | translate }}</h2>
                        </div>
                        <div class="invitingEducators" >
                            <h2>{{ "inviting_educators" | translate }}</h2>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>


    </swiper-container>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons  slot="start">
            <ion-button [disabled]='disablePreviousButton()' (click)='previous()'>{{ "previous" | translate }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button [disabled]='loading || disableNext' (click)='next()'>{{getTextForNextButton()}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
