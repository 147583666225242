// angular
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// ionic
import {
    LoadingController,
    AlertController,
    ModalController,
    IonInfiniteScroll,
    ToastController,
    NavController,
    Platform,
    IonRefresher,
    IonRouterOutlet
} from '@ionic/angular';

// pages
import { UserDetailsPromptPage } from '../user-details-prompt/user-details-prompt-page';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';

// services
import { Constants } from 'src/app/app.constants';
import { AppData } from 'src/app/services/app-data.service';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';
import { VideosApi } from 'src/app/services/api/videos-api.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { SubjectsApi } from 'src/app/services/api/subjects.service';
import { WatchedVideoApi } from 'src/app/services/api/watched-videos-api.service';
import { SharedUiService } from 'src/app/services/shared-ui.service';
import { FavoredVideosApi } from 'src/app/services/api/favored-videos-api.service';

// models
import { WatchedVideo } from 'src/app/models/watched-video';
import { IFavoredVideo } from 'src/app/interfaces/IFavoredVideo';
import { ISubject } from 'src/app/interfaces/ISubject';

// libraries
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { zip } from 'rxjs';
import { IVideo } from '../../interfaces/IVideo';
import { UsersApi } from '../../services/api/users-api.service';

@Component({
    selector: 'app-video-catalog',
    templateUrl: 'video-catalog-page.html',
    styleUrls: [
        'video-catalog-page.scss',
        '../../components/video-catalog-card/video-catalog-card.component.scss' // Needed for "Hot Topic" card
    ]
})
export class VideoCatalogPage extends AbstractRootMenuPage implements OnInit, OnDestroy {
    protected offset = 0;
    protected pageSize = 20;
    public isVideosAvailableForGroupSection = false;
    public watchedVideos: WatchedVideo[] = [];
    protected favoredVideos: IFavoredVideo[] = [];
    @ViewChild(IonInfiniteScroll, { static: false }) protected infiniteScroll: IonInfiniteScroll;
    public tag: string;
    public subjectSlug: string;
    public subject: ISubject;
    public categorySlug: string;
    public special: 'my-tasks' | 'no-subject-with-tasks';
    public withTasks = false;
    public headerTitle: string;
    public showHotTopic = true;
    @ViewChild('refresher', { static: false }) protected refresher: IonRefresher;
    public refresherProgress = 0;
    protected refreshCancelTimeout: any;
    public showLoadingIndicator = false;
    public showSearch = false;
    protected videos: IVideo[];
    cachedSearchText: string;
    public searchText: string;
    @ViewChild('search', { static: false }) search: any;
    public showPublishDate = false;
    private websource_id = '';

    constructor(public navCtrl: NavController,
        public appData: AppData,
        public groupApi: GroupsApi,
        public videosApi: VideosApi,
        public subjectsApi: SubjectsApi,
        public watchedVideosApi: WatchedVideoApi,
        public favoredVideosApi: FavoredVideosApi,
        public constants: Constants,
        public loadingCtrl: LoadingController,
        public alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public uiUtils: UiUtils,
        public analytics: AnalyticsService,
        public translate: TranslateService,
        public modalCtrl: ModalController,
        protected appManager: AppManager,
        protected titleService: Title,
        protected logger: NGXLogger,
        protected _route: ActivatedRoute,
        protected router: Router,
        public plt: Platform,
        private sharedUiService: SharedUiService,
        protected routerOutlet: IonRouterOutlet) {
        super(plt);
        // this.items = new Array({src: "http://placehold.it/580x400"},{src: "http://placehold.it/280x400"},{src: "http://placehold.it/180x300"},{src: "http://placehold.it/680x500"},{src: "http://placehold.it/280x700"});

    }

    ionViewWillLoad() {
        this.logger.debug('ionViewWillLoad');
        // will be loaded in ionViewWillEnter as well, but we need it here to have it once the view is rendered for the first time
    }

    ngOnInit() {
        this.logger.debug('ngOnInit');
        this.titleService.setTitle('Video Catalog | uugot.it');
        this.analytics.trackPageView('video-catalog');
        this.showHotTopic = this.appData.remoteConfig.enableHotTopic;
        // ### Show dialog that asks the user for his/her email address and language levels:

        // let userId = this.appData.getPreferenceString(this.constants.pref.USER_EMAIL_ENCRYPTED);
        // if(!userId) {
        //   this.presentUserDetailsModal();
        // }
    }
    ngOnDestroy() {
        if (this.searchText) {
            this.appData.forceReloadVideos();
        }
    }

    ionViewWillEnter() {
        this.logger.debug('ionViewWillEnter');
        // this.appData.activePage = 'catalog';
        let websource_id = this._route.snapshot.params?.websource_id;
        if (!websource_id && this.appData.isLoggedIn()) {
            websource_id = 'all';
        } else if (!websource_id) {
            websource_id = 'orftvthek';
        }

        // if (this.appData.lastCatalogSearch) {
        // this.searchText = this.appData.lastCatalogSearch;
        // this.showSearch = true;
        // }

        if (this.tag) {
            this.headerTitle = this.tag;
        } else {
            this.headerTitle = this.appData.isEducator()
                ? this.translate.instant('video_library')
                : this.translate.instant('all_videos');
            // Show websource title in header:
            // const source = this.appData.getPreferenceString(
            //     this.constants.pref.VIDEO_SOURCE,
            //     this.constants.DefaultVideoSource
            // );
            // this.headerTitle = `${this.headerTitle} - ${
            //     this.appData.getWebsourceById(source)?.name
            // }`;
        }

        if (websource_id !== this.websource_id) {
            this.websource_id = websource_id;
            this.loadVideoCatalog(true, () => {
                this.videos = this.getAppDataVideos();
            });
        } else if (this.appData.shouldReload()) {
            this.offset = 0;
            this.appData.videos = [];
            this.loadVideoCatalog(true, () => {
                this.videos = this.getAppDataVideos();
            });
        } else {
            this.loadWatchedVideos();
        }

        // console.log('infiniteScroll:', this.infiniteScroll);
        this.infiniteScroll.disabled = this.appData.allVideosLoaded;

        if (this.isRootPage) {
            // Exit app subscription
            super.creatBackButtonSubscription();
        }
        this.updateFavoredVideos();
    }

    // Workaround for https://gitlab.com/uugotitTeam/webapp/issues/172

    ionViewDidEnter() {
        // Show terms if not accepted already
        const termsAccepted = this.appData.getPreferenceString(`${this.constants.pref.TERMS_ACCEPTED}_0`);
        if (!termsAccepted) {
            this.appManager.showTermsOfUseDialogV2('0', true);
        }
    }

    ionViewWillLeave() {
        if (this.isRootPage) {
            // Exit app subscription
            super.removeBackButtonSubscription();
        }
    }

    /**
     * Presents user details dialog
     */
    async presentUserDetailsModal() {
        const userDetailsModal = await this.modalCtrl.create({
            component: UserDetailsPromptPage,
            showBackdrop: true,
            backdropDismiss: false
        });
        await userDetailsModal.present();
    }

    loadingAnimation(showLoadingAnimation: boolean) {
        if (showLoadingAnimation) {
            // await loader.present();
            this.showLoadingIndicator = true;
        }
    }

    setCountry() {
        let country = this.appData.geoIp ? this.appData.geoIp.country : null;
        if (country === 'ZZ') { // when running on localhost for example
            country = 'AT';
        }
        return country;
    }

    async loaderErrorHandler(err, showLoadingAnimation: boolean) {
        const loader = await this.loadingCtrl.create({
            message: this.translate.instant('loading_please_wait'),
        });

        const reloadHandler = () => {
            this.logger.debug('user wants to reload');
            this.loadVideoCatalog(true, () => {
                // Do nothing.
            });
        };
        const onError = () => {
            this.logger.error('Error reloading data: %s', err.message, err);
            if (err.status !== this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
                this.uiUtils.showErrorAlert(err.error.message, null, reloadHandler);
            }
        };
        if (showLoadingAnimation) {
            loader.dismiss().then(onError);
        } else {
            onError();
        }
    }

    async loadVideoCatalogFromSearch(showLoadingAnimation: boolean, onComplete: () => void) {
        this.loadingAnimation(showLoadingAnimation);
        let source = this.appData.getPreferenceString(this.constants.pref.VIDEO_SOURCE, this.constants.DefaultVideoSource);
        if (this.searchText) {
            source = null;
        }

        this.videosApi.getUserSpecificCatalog(source, this.offset, this.pageSize, this.searchText, '', this.setCountry(), this.tag).subscribe(responseCatalogue => {
            // Solves 'cannot read property length of undefined'
            if (!responseCatalogue.videosAvailable) {
                responseCatalogue.videosAvailable = [];
            }

            console.log(`Reload data got ${responseCatalogue.videosAvailable.length} videos in search`);
            const onSuccess = async () => {
                this.appData.videos = [];
                console.log(`Search text: ${this.searchText}, offset: ${this.offset}`);
                this.appData.replaceVideos(responseCatalogue.videosAvailable);
                this.offset = this.pageSize;
                // this.analytics.trackAnalyticsEvent(AnalyticsCategory.VideoCatalog, 'loaded', source);
                this.appData.allVideosLoaded = this.offset >= responseCatalogue.videosAvailableCount;
                this.logger.debug(`Loaded all available videos (${this.offset} / ${responseCatalogue.videosAvailableCount})?`, this.appData.allVideosLoaded ? 'yes' : 'no');
                this.infiniteScroll.disabled = this.appData.allVideosLoaded;
                this.loadWatchedVideos();
                this.updateFavoredVideos();
            };
            if (showLoadingAnimation) {
                // loader.dismiss().then(onSuccess);
                this.showLoadingIndicator = false;
                onSuccess();
            } else {
                onSuccess();
            }
        }, err => {
            this.loaderErrorHandler(err, showLoadingAnimation);
        },
        () => {
            this.logger.debug('Reload data complete');
            onComplete();
        });
    }


    /**
     * Loads next videos in the video catalog
     *
     * @param showLoadingAnimation Show loading animation if true
     * @param onComplete Function to run when loading complete
     */
    async loadVideoCatalog(showLoadingAnimation: boolean, onComplete: () => void) {
        this.loadingAnimation(showLoadingAnimation);
        let source = this.appData.getPreferenceString(this.constants.pref.VIDEO_SOURCE, this.constants.DefaultVideoSource);
        if (this.websource_id) {
            source = this.websource_id;
            this.appData.activePage = `catalog/${this.websource_id}`;
        }
        if (this.searchText) {
            source = null;
        }

        // Combine the two requests
        // eslint-disable-next-line function-paren-newline
        const apiRequests = zip(
            // Load video of the day:
            this.videosApi.getVideosOfTheDay(0, 1),
            // Load video catalog:
            this.videosApi.getUserSpecificCatalog(source, this.offset, this.pageSize, this.searchText, '', this.setCountry(), this.tag));

        apiRequests.subscribe(data => {
            // Destructure array
            const [responseOfDay, responseCatalogue] = data;

            if (responseCatalogue.customer && responseCatalogue.customer.license) {
                this.appData.savePreferenceString(this.constants.pref.LICENSE, JSON.stringify(responseCatalogue.customer.license));
                this.appData.userLicense = responseCatalogue.customer.license;
            }
            // console.log('Got videos of the day: ', data);
            if (responseOfDay.data.length > 0 && !this.tag) {
                const videoOfTheDay = responseOfDay.data[0];
                videoOfTheDay['isVideoOfTheDay'] = true;
                this.appData.videoOfTheDay = videoOfTheDay;

                // Remove video of the day from normal videos so it doesn't appear twice
                if (responseCatalogue.videosAvailable && videoOfTheDay) {
                    responseCatalogue.videosAvailable = responseCatalogue.videosAvailable.filter(video => {
                        return video._id !== videoOfTheDay._id;
                    });
                }
            }

            // Solves 'cannot read property length of undefined'
            if (!responseCatalogue.videosAvailable) {
                responseCatalogue.videosAvailable = [];
            }

            console.log(`Reload data got ${responseCatalogue.videosAvailable.length} videos`);

            const onSuccess = async () => {
                if (this.offset === 0) {
                    // Clear videos, in case of pull-to-refresh
                    this.appData.videos = [];
                }
                this.appData.addVideos(responseCatalogue.videosAvailable);
                this.offset += this.pageSize;
                this.analytics.trackAnalyticsEvent(AnalyticsCategory.VideoCatalog, 'loaded', source);
                this.appData.allVideosLoaded = this.offset >= responseCatalogue.videosAvailableCount;
                this.logger.debug(`Loaded all available videos (${this.offset} / ${responseCatalogue.videosAvailableCount})?`, this.appData.allVideosLoaded ? 'yes' : 'no');
                this.infiniteScroll.disabled = this.appData.allVideosLoaded;

                this.loadWatchedVideos();
                this.updateFavoredVideos();
            };

            if (showLoadingAnimation) {
                // loader.dismiss().then(onSuccess);
                this.showLoadingIndicator = false;
                onSuccess();
            } else {
                onSuccess();
            }
        }, err => {
            this.loaderErrorHandler(err, showLoadingAnimation);
        },
        () => {
            this.logger.debug('Reload data complete');
            onComplete();
        });
    }

    async loadWatchedVideos(): Promise<void> {
        const videoIds = this.getAppDataVideos().map(v => v._id);
        if (this.appData.isLoggedIn()) {
            const watchedVideos = await this.watchedVideosApi.listByVideos(videoIds).toPromise();
            this.watchedVideos = watchedVideos.data?.map(wv => {
                const w = new WatchedVideo(wv.video_id, wv.watchedUntil, wv.expiryDate, wv.dur, wv.fin);
                return w;
            }) || [];
        } else {
            this.watchedVideos = this.appData.getWatchedVideos().filter(w => videoIds.includes(w.videoId));
        }
    }

    /**
     * Refreshes video catalog (ion-refresher "ionRefresh" event)
     *
     * @param event ion-refresher event
     */
    doRefresh(event: any) {
        this.logger.debug('Begin refresh operation', event);

        this.offset = 0;
        // this.infiniteScroll.disabled = false;

        this.loadVideoCatalog(false, () => {
            this.logger.debug('Refresh operation has ended');
            this.videos = this.getAppDataVideos();
            event.target.complete();
        });
    }

    /**
     * ion-refresher "ionPull" event
     * @param event ion-refresher event
    */
    onRefresherPull(event: any) {
        //Emitted while the user is pulling down the content and exposing the refresher.
        // console.log('ionPull Event Triggered!');
        this.updateRefresherProgress();
    }

    /**
     * Calls refresher.getProgress() and sets the value as this.refresherProgress,
     * @param override if set, then this overrides the value from refresher.getProgress()
     */
    updateRefresherProgress(override?: number) {
        if (override != undefined) {
            this.refresherProgress = override;
        } else {
            this.refresher.getProgress().then((progress) => {
                // console.log('updateRefresherProgress', progress);
                this.refresherProgress = progress;
                if (progress > 0 && progress < 1) {
                    if (this.refreshCancelTimeout) {
                        clearTimeout(this.refreshCancelTimeout);
                        this.refreshCancelTimeout = null;
                    }
                    this.refreshCancelTimeout = setTimeout(() => {
                        this.updateRefresherProgress();
                    }, 300);
                }
            });
        }
    }

    /**
     * Loads next videos in catalog using Ion Infinite Scoll
     *
     * @param event Ion infinite scroll event
     */
    doInfinite(event: any) {
        this.logger.debug('doInfinite', event);
        this.loadVideoCatalog(false, () => {
            this.logger.debug('Async infinite scroll reload operation has ended');
            this.videos = this.getAppDataVideos();
            event.target.complete();
        });
    }

    getAppDataVideos() {
        if (this.searchText && this.appData.videoOfTheDay) {
            const videos = this.appData.videos;
            if (videos[0]) {
                videos.shift();
            }
            return videos;
        } else {
            return this.appData.videos;
        }
    }

    getVideos() {
        return this.videos;
    }

    navigateBack() {
        this.logger.debug('navigateBack does nothing');
    }

    /**
     * Returns true of the current page is a root page in terms of navigation hierarchy.
     */
    get isRootPage(): boolean {
        if (this.tag === 'kids') {
            return true;
        }
        if (this.tag || this.subjectSlug || this.special || this.isVideosAvailableForGroupSection) {
            return false;
        } else {
            return true;
        }
    }

    get isShowHotTopic() {
        return this.showHotTopic;
    }

    updateFavoredVideos() {
        if (!this.appData.isLoggedIn()) {
            this.getAppDataVideos()?.forEach(video => {
                this.appData.getFavoredVideo(video._id).then(favoredVideo => {
                    video.favored = !!favoredVideo;
                });
            });
        }
    }
    activateSearch() {
        this.showSearch = true;
        setTimeout(() => {
            this.search.setFocus();
        }, 500);
    }

    closeSearchBar() {
        this.showSearch = false;
        this.cancelSearch();
    }

    clickOutsideSearchBar() {
        this.showSearch = false;
    }

    cancelSearch() {
        this.searchText = null;
        this.onSearchChange();
    }

    onSearchChange() {
        this.offset = 0;
        if (this.searchText === this.cachedSearchText) {
            this.logger.debug('Cached search results.');
            return null;
        }
        this.cachedSearchText = this.searchText;
        this.appData.setCatalogSearch(this.searchText);
        this.showLoadingIndicator = true;
        this.loadVideoCatalogFromSearch(true, () => {
            if (this.appData.remoteConfig.enableHotTopic && !this.searchText) {
                this.showHotTopic = true;
            }
            this.showPublishDate = !!this.searchText;
            this.logger.debug('Doing search with: ' + this.searchText);
            this.videos = this.getAppDataVideos();
        });
    }
}
