/* eslint-disable @typescript-eslint/camelcase */
// angular
import { Component, ElementRef, OnInit, OnDestroy, ViewEncapsulation, ViewChild, AfterViewInit, HostListener, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { Subscription, interval, Observable, from } from 'rxjs';

// ionic
import { PopoverController, AlertController, ToastController, Platform, NavController, LoadingController, MenuController, AnimationController, ActionSheetController, GestureController, Gesture, ModalController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

// services
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { WordCatalogApi } from 'src/app/services/api/wordcatalog.service';
import { TranslationsApi } from 'src/app/services/api/translations-api.service';
import { VideosApi } from 'src/app/services/api/videos-api.service';
import { ActivityService } from 'src/app/services/activity.service';

// constants
import { Constants } from 'src/app/app.constants';

// models
// import { WatchedVideo } from 'src/app/models/watched-video';
import { SavedWord } from 'src/app/models/saved-word.model';
import { IVideo } from 'src/app/interfaces/IVideo';
import { ITranslationSubtitles, ITranslationOptions, ITranslation, IWord } from 'src/app/interfaces/ITranslation';
import { ILanguages } from 'src/app/interfaces/ILanguages';
import { IVideoPlaybackUrl } from 'src/app/interfaces/IVideoPlaybackUrl';
import { IActivityEvent, ActivityCategory, ActivityAction } from 'src/app/interfaces/IActivity';

// libraries
import { TranslateService } from '@ngx-translate/core';
// import videojs from 'video.js'; // --> This doesn't seem to work: "[ts] Cannot find module 'video.js'."
// import { PopoverModule } from "ng2-popover";
declare const videojs: any; // VideoJSStatic doesn't work any more because of the videojs-offset.js extension
declare const Drop: any; // not used, we use Popper instead
declare const Popper: any;
// import * as Drop from 'tether-drop'; // --> This gives a runtime error "Uncaught TypeError: extend is not a function" in tether.js
import { NGXLogger } from 'ngx-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';

import { Utils } from 'src/app/utils/utils';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';
import { Share } from '@capacitor/share';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { environment } from 'src/environments/environment';
import { VideoPlayerEmbedPopoverPage } from './video-player-embed-popover.page';
import { VideoIntroAnimation } from './video-intro-animation';
import { ILanguage } from 'src/app/interfaces/ILanguage';
import { SubtitlesApi } from 'src/app/services/api/subtitles.service';
import { AppComponent } from 'src/app/app.component';
import { VideoPlayNextOverlay } from './video-playNext-overlay';
import { SharedUiService } from 'src/app/services/shared-ui.service';
import { INLPToken } from 'src/app/interfaces/ISubtitle';
import { IFavoredVideo } from 'src/app/interfaces/IFavoredVideo';
import { FavoredVideosApi } from 'src/app/services/api/favored-videos-api.service';
import { VideoPlayerSettings } from '../../components/video-player-settings/video-player-settings.component';
import { ISettingsListener, ISettingsMainItem, ISettingsConfiguration } from '../../components/video-player-settings/video-player-settings.interfaces';
import { VideoOverlayIntro2 } from '../../components/video-overlay-intro2/video-overlay-intro2.component';
import { IVideoOverlayIntroConfiguration } from '../../components/video-overlay-intro2/video-overlay-intro2.interfaces';
import { VideoPlayerScreenLock } from '../../components/video-player-screen-lock/video-player-screen-lock.component';
import { TasksApi } from '../../services/api/tasks.service';
import { VideoPlayerTaskOverlayComponent, VjsMarkerClass } from 'src/app/components/video-player-task-overlay/video-player-task-overlay.component';
import { IVideoPlayerTaskOverlayListener } from 'src/app/components/video-player-task-overlay/video-player-task-overlay.interfaces';
import { VideoPlayerEducatorOverlayComponent } from 'src/app/components/video-player-educator-overlay/video-player-educator-overlay.component';
import { IClip } from 'src/app/interfaces/IClip';
import { ClipsApi } from 'src/app/services/api/clips.service';
import { ILeaderboardOverlayListener } from 'src/app/components/video-player-leaderboard-overlay/video-player-leaderboard-overlay.interfaces';
import { VideoPlayerLeaderboardOverlay } from '../../components/video-player-leaderboard-overlay/video-player-leaderboard-overlay.component';
import { IGroupVideo } from 'src/app/interfaces/IGroupVideo';
import {
    EmbedShareModalComponent
} from '../../components/embed-share-modal/embed-share-modal.component';

export interface IPopover {
    popper: { // "Popper" instance
        close: () => {};
    };
    subtitlesIndex: number; // index of the subtitles frame/chunk
    wordIndex: number;
    closeTimeout?: ReturnType<typeof setTimeout>; // Timeout that will be set for automatically closing the popper
}

@Component({
    template: `

        <ion-list class="popover-page">
            <ion-item>
                <ion-label>{{ "playback_rate" | translate }}</ion-label>
                <button ion-button item-right outline icon-left (click)="switchPlaybackRate(+1)" class="switchRateButton">{{playbackRate}}x</button>
                <!--
                <ion-select [(ngModel)]="playbackRate" (ionChange)="changePlaybackRate()">
                  <ion-select-option value="0.5">0.5x</ion-select-option>
                  <ion-select-option value="0.75">0.75x</ion-select-option>
                  <ion-select-option value="1.0">1x</ion-select-option>
                  <ion-select-option value="1.5">1.5x</ion-select-option>
                </ion-select>
                -->
            </ion-item>
        </ion-list>

    `
})
export class VideoPlayerPopoverPage implements OnInit {
    public playbackRate: number;
    private switchPlaybackRateCallback: (number) => number;

    constructor(
        private analytics: AnalyticsService,
        public appData: AppData,
        private logger: NGXLogger) {
        // do nothing.
    }

    ngOnInit() {
        if (this.appData.playBackRate) {
            this.playbackRate = this.appData.playBackRate;
            this.logger.debug(`Got playback rate ${this.playbackRate}`);
        }
        if (this.appData.switchPlaybackRateCallback) {
            this.switchPlaybackRateCallback = this.appData.switchPlaybackRateCallback;
        }
    }

    /**
     * Changes video play back rate
     *
     * @param step The amount to add to the current playback rate
     */
    switchPlaybackRate(step: number) {
        this.logger.debug('switchPlaybackRate %s', step);
        this.playbackRate = this.switchPlaybackRateCallback(step);
        this.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'switch_playback', this.playbackRate.toString());
    }
}


@Component({
    selector: 'app-video-player',
    templateUrl: 'video-player-page.html',
    styleUrls: ['video-player-page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerPage implements OnInit, OnDestroy, AfterViewInit, ISettingsListener, IVideoPlayerTaskOverlayListener, ILeaderboardOverlayListener {
    private initialTimeInVideo: number;
    private initialTimeInVideoInitDone = false; // Will be set to true once the inital time has been set on the video player
    private isInitialPlay = true;
    private isFirstPlayEventForVideo = true;
    private isFirstEndEventForVideo = true;
    public videoJSplayer: any;
    private lastTimeSaved: number;
    private lastTimeSeeking: number; // Timestamp (in milliseconds) if the last video seeking event
    private translation: ITranslation;
    private currentChunkIndex = -1;
    private popovers: Array<IPopover> = [];
    private lastWordClickedTime: number;
    public language: ILanguages;
    private playbackRates = [0.6, 0.75, 0.9, 1.0, 1.2];
    public playbackRateIndex: number;
    private pressTimer: number;
    private wordClicked: boolean;
    private goBackSecondsAfterResume; // How many seconds the video will automatically go back after resuming it
    public videoInfo: IVideo;
    public favoredVideo: IFavoredVideo;
    public currentTime: number;
    public currentSubtitle: string;
    public isVideoFullscreen = false;
    public playbackRate: number; // The currently selected playback rate
    public showActionMenu = false; // If this is true, then a action bar menu will be shown (in full screen it's an overlay button)
    public translationSubscription: Subscription;
    public class_id: string;
    public groupVideo: IGroupVideo;
    public isShareEnabled = false;
    public showLoadingProgress = true;
    public tag: string;
    public subjectSlug: string;
    public failedServerResponse: { msg?: string; code?: string; };
    public playClicked = false;
    public maxLoops = 1; // 1 = disable looping
    private loopCounter = 0; // Current loop counter
    public clippingStart = -1; // -1 = no clipping
    public clippingEnd = -1; // -1 = no clipping
    protected autoStart = false; // If true, the video will start playing automatically without user interaction
    private autoStartExecuted = false; // Will be set to true once the autostart has been executed
    private isVideoMetadataLoaded = false; // Will be set to true once the video.js player has loaded the video metadata (inc. duration)
    private isSubtitlesLoaded = false; // Will be set to true once the subtitles (translation) has been loaded
    customURLLogo: string;
    adShown = false;
    adDuration: number;

    private showVideoJsBigPlayButton = false;

    // Enable (default) or disable certain videojs events - if disabled they will not call our custom functions
    private videoJsEventsEnabled = {
        volumechange: true
    }



    private backRoute: string; // The back route to which the user will navigate back

    public introAnimation: VideoIntroAnimation;
    private subtitleLoadingDialog: HTMLIonLoadingElement;

    // @ViewChild('video-overlay-settings', { static: false }) public videoOverlaySettingsEl: ElementRef;
    @ViewChild('videoContainer', { read: ElementRef }) public videoContainer: ElementRef;

    public settingsOverlayShown = false;
    public leaderboardOverlayShown = false;
    protected analyticsPageId = 'video-player';
    private lastAnalyticsPlayingEvent: moment.Moment; // Timestamp of the last "playing" event that was sent to analytics
    private lastTimeUserActive: moment.Moment = moment(); // Timestamp of last "useractive" event (from video.js)

    public wordsClicked: IWordCatalog[] = []; // Temporarily save all the words that the user clicked
    // protected wordsClicked: IWordCatalog[] = [
    //     { fromWord: 'Haus', toWord: 'house', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    //     { fromWord: 'Bürgermeister', toWord: 'mayor', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    //     { fromWord: 'Gesellschaftskonstruktion', toWord: 'legal form of the association', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    //     { fromWord: 'Abschluss', toWord: 'conclusion / ending / finals', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    //     { fromWord: 'Wienerinnen', toWord: 'Viennese women', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    //     { fromWord: 'Bundesregierung', toWord: 'Federal government', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    //     { fromWord: 'Wissenstransfer', toWord: 'knowledge transfer', video_id: null, translation_id: null, subtitles_index: 0, fromWords_index: 0, from: 'en', to: 'de', oriWord: '', oriWords_index: 0 },
    // ];
    public showPostroll = false; // If true, the post-roll will be shown

    public videoPlayNextOverlay: VideoPlayNextOverlay;

    private screenOrientationSubscription: Subscription;

    private isIntitialFullscreenChange = false;

    @ViewChild(VideoPlayerSettings) settingsComponent: VideoPlayerSettings;
    @ViewChild(VideoOverlayIntro2) videoOverlayIntro2: VideoOverlayIntro2;
    @ViewChild(VideoPlayerScreenLock) screenLockComponent: VideoPlayerScreenLock;
    @ViewChild(VideoPlayerEducatorOverlayComponent) videoPlayerEducatorOverlay: VideoPlayerEducatorOverlayComponent;
    @ViewChild(VideoPlayerTaskOverlayComponent) videoPlayerTaskOverlay: VideoPlayerTaskOverlayComponent;
    @ViewChild(VideoPlayerLeaderboardOverlay) videoPlayerLeaderboardOverlay: VideoPlayerLeaderboardOverlay;


    // Properties for click delay:
    public clickDelay = {
        timeout: null,
        timeoutStart: null,
        timeoutEnd: null,
        showProgressBar: false,
        countdownPercent: 100,
        isPausedInfinite: false
    } as {
        timeout?: ReturnType<typeof setTimeout>; // Timeout that will used for the "click delay"
        timeoutStart?: moment.Moment;
        timeoutEnd?: moment.Moment;
        showProgressBar: boolean;
        countdownPercent: number;
        isPausedInfinite: boolean;
    };

    private wordCatalogLimitMessageCounter = 0;

    /**
     * This will be true if the page is embedded in an iframe (ReachAll)
     */
    public isEmbedded = false;

    public isYouTubeVideo = false;

    public clip: IClip;
    public canPlayVideo = false;

    constructor(
        protected _route: ActivatedRoute,
        public navCtrl: NavController,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public elementRef: ElementRef,
        public popoverCtrl: PopoverController,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public analytics: AnalyticsService,
        protected alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public loadingCtrl: LoadingController,
        public plt: Platform,
        public groupsApi: GroupsApi,
        public wordCatalogApi: WordCatalogApi,
        public translationsApi: TranslationsApi,
        public videosApi: VideosApi,
        public activityService: ActivityService,
        private router: Router,
        private titleService: Title,
        protected metaService: Meta,
        protected logger: NGXLogger,
        private menu: MenuController,
        private animationCtrl: AnimationController,
        public subtitlesApi: SubtitlesApi,
        private appComponent: AppComponent,
        private actionSheetController: ActionSheetController,
        private sharedUiService: SharedUiService,
        private favoredVideosApi: FavoredVideosApi,
        private screenOrientation: ScreenOrientation,
        private gestureCtrl: GestureController,
        private ngZone: NgZone,
        private tasksApi: TasksApi,
        private clipsApi: ClipsApi,
        private modalController: ModalController,
        protected domSanitizer: DomSanitizer,
    ) {
        const nav = this.router.getCurrentNavigation();
        this.logger.debug('VideoPlayerPage getCurrentNavigation.extras', nav ? nav.extras : '-');
        if (nav && nav.extras && nav.extras.state) {
            if (nav.extras.state.maxLoops !== null && nav.extras.state.maxLoops !== undefined) {
                this.maxLoops = nav.extras.state.maxLoops;
                this.clippingStart = nav.extras.state.clippingStart;
                this.clippingEnd = nav.extras.state.clippingEnd;
                this.autoStart = nav.extras.state.autoStart;
            }
            this.tag = nav.extras.state.tag;
            this.subjectSlug = nav.extras.state.subjectSlug;
            this.backRoute = nav.extras.state.backRoute;
            this.initialTimeInVideo = nav.extras.state.initialTimeInVideo;
            this.language = nav.extras.state.toLanguage;
            this.leaderboardOverlayShown = nav.extras.state.showLeaderboard || false;
            this.groupVideo = nav.extras.state.groupVideo;

            if (nav.extras.state.showToastText) {
                // Display a toast 🍞
                uiUtils.displayToast(nav.extras.state.showToastText);
            }
        }
        this.videoPlayNextOverlay = new VideoPlayNextOverlay(this.logger, !this.isIOsWeb);
        this.goBackSecondsAfterResume = this.constants.VideoGoBackAfterResumeSeconds;
    }

    ionViewWillEnter() {
        if (this.videoInfo) {
            this.logger.info('ionViewWillEnter videoInfo is already defined - returning');
            return;
        }
        if (!this.language) {
            this.language = this.appData.getPreferenceString(
                this.constants.pref.TRANSLATION_LANG,
                this.appData.constants.DefaultTranslationLang
            ) as ILanguages;
            this.logger.debug('Language hasn\'t been provided in navigation extras.state, take it from preferences:', this.language);
        } else {
            this.logger.debug('Language has been provided in navigation extras.state:', this.language);
        }
        this.playbackRateIndex = parseInt(
            this.appData.getPreferenceString(
                this.constants.pref.PLAYBACK_RATE_INDEX,
                this.appData.constants.DefaultPlaybackRateIndex
            ),
            10
        );
        if (this.playbackRateIndex > this.playbackRates.length - 1) {
            this.playbackRateIndex = this.playbackRates.length - 1;
        }
        this.playbackRate = this.playbackRates[this.playbackRateIndex];
        this.logger.debug(
            `playbackRateIndex=${this.playbackRateIndex}, playbackRate=${this.playbackRate}`
        );

        // this.initialTimeInVideo = this.appData.timeInVideo;
        // this.logger.debug('ionViewWillEnter() initialTimeInVideo=', this.initialTimeInVideo);
        // this.appData.timeInVideo = null;

        this.class_id = this._route.snapshot.params.class_id;

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisPage = this;

        const redirectToStart = () => {
            setTimeout(() => {
                this.navCtrl.navigateRoot('/');
            }, 3000);
        };

        this._route.snapshot.data.resData.subscribe(
            async (data) => {
                const videoData = data?.video;
                const favoredData: IFavoredVideo = data?.favored;
                const clip = data?.clip;

                if (videoData?.success === false) {
                    // this.uiUtils.displayToast(data.msg);
                    this.showLoadingProgress = false;
                    this.failedServerResponse = videoData;
                    this.logger.debug('Got failedServerResponse', this.failedServerResponse);
                    this.metaService.updateTag({ name: 'robots', content: 'noindex' });
                    if (videoData.code !== 'REACHALL_VIDEO_DEACTIVATED') {
                        redirectToStart();
                    }
                    return;
                }
                if (videoData) {
                    this.logger.debug('### data', data);
                    this.videoInfo = videoData;
                    this.canPlayVideo = videoData.canPlayLimitCredits;
                    if (videoData.willDeductCredits) {
                        const alert = await this.alertCtrl.create({
                            header: 'Premium Video',
                            message: this.translate.instant('premium_content_info_spending_credits'),
                            buttons: [
                                {
                                    text: this.translate.instant('button_continue'),
                                    role: 'cancel'
                                },
                                {
                                    text: this.translate.instant('video_library'),
                                    handler: () => {
                                        this.navCtrl.navigateRoot('/catalog/all');
                                    },
                                },
                            ],
                        });
                        await alert.present();
                    }
                    this.customURLLogo = videoData.customURLLogo ? videoData.customURLLogo : 'assets/img/reachall_logo_2024.png';
                    this.favoredVideo = favoredData;
                    this.clip = clip;
                    this.showLoadingProgress = !!(
                        thisPage.initialTimeInVideo || thisPage.maxLoops > 1
                    );
                    this.checkLicense(this.videoInfo);
                    this.initVideo();
                    if (this.canPlayVideo) {

                        this.loadSubtitles();
                        this.updateHtmlMeta();
                        const websource = this.appData.getWebsourceById(this.videoInfo.websource_id);
                        this.isShareEnabled = websource && !websource.isScooling;

                        // this.updateWatchedVideo(0, true);

                        // We need to show the leaderboard
                        if (this.clip && this.clip.leaderboard && this.leaderboardOverlayShown) {
                            this.showLeaderboardOverlay();
                        }
                    }

                } else {
                    this.logger.debug('VideoPlayerPage got no data');
                }
            },
            (err) => {
                this.logger.warn('Error reloading data:', err);
                this.showLoadingProgress = false;
                this.failedServerResponse = {
                    msg: err.error ? err.error.message : err.status,
                };
                this.metaService.updateTag({ name: 'robots', content: 'noindex' });
                const canGoBack = this.appComponent.routerOutlet.canGoBack();
                if (this.class_id) {
                    if (err.status !== this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
                        this.uiUtils.showErrorAlert(err.error.message);
                    }
                    if (canGoBack) {
                        this.appComponent.routerOutlet.pop();
                    } else if (this.backRoute) {
                        this.navCtrl.navigateRoot(this.backRoute);
                    } else {
                        let backRoute = 'catalog';
                        if (this.appData.authenticatedUser.role === 'educator') {
                            backRoute = `educator-class/details/${this.class_id}`;
                        } else {
                            backRoute = `student-class/details/${this.class_id}`;
                        }
                        this.navCtrl.navigateRoot(backRoute);
                    }
                } else {
                    redirectToStart();
                }
            },
            () => this.logger.debug('Loading video info data complete')
        );

        // We use this to prevent that the video ControlBar is shown if the user clicks somewhere on the area around the subtitles.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const subtitleElement = document.querySelector('#video-subtitle') as HTMLElement;
        subtitleElement.onclick = function(event) {
            thisPage.logger.debug('onclick event of video-subtitle div');
            thisPage.lastWordClickedTime = new Date().getTime();
        };

        this.screenOrientationSubscription = this.screenOrientation.onChange().subscribe(() => {
            this.ngZone.run(() => {
                // This code will run in Angular's execution context - see https://capacitorjs.com/docs/guides/angular
                this.onScreenOrientationChange();
            });
        });
        // get current screen orientation (see https://github.com/apache/cordova-plugin-screen-orientation)
        this.logger.debug('screen orientation is', this.screenOrientation.type); // logs the current orientation, example: 'landscape'
        if (this.screenOrientation.type?.startsWith('landscape')) {
            this.appComponent.setStatusBarStyleDark();
            this.appComponent.hideStatusBar();
        }
    }

    ionViewDidEnter() {
        this.logger.debug('ionViewDidEnter');
        if (this.plt.is('mobile')) {
            this.menu.enable(false);
        }
    }

    ionViewWillLeave() {
        this.logger.debug('ionViewWillLeave');
        if (this.plt.is('mobile')) {
            this.menu.enable(true);
        }
        if (this.playClicked) {
            this.updateWatchedVideo(this.videoJSplayer?.currentTime(), true);
        }
        this.screenOrientationSubscription.unsubscribe();
    }

    ionViewDidLeave() {
        this.logger.debug('ionViewDidLeave');
        this.videoJSplayer?.pause(); // can be null if init failed (video does not extist)
        this.allowSleepAgain('ionViewDidLeave');
        this.videoPlayNextOverlay?.unsubscribeInterval();
        this.appComponent.showStatusBar();
        this.appComponent.setStatusBarStyleLight();
        this.screenOrientation.unlock();
    }

    ngOnDestroy() {
        this.logger.debug('ngOnDestroy');
        this.videoJSplayer?.dispose(); // can be null if init failed (video does not extist)
    }

    ngOnInit() {

        this.logger.debug('VideoPlayerPage ngOnInit with ID', this.videoInfo?._id);
        this.analytics.trackPageView(this.analyticsPageId);
    }

    ngAfterViewInit() {
        this.logger.debug('ngAfterViewInit() videoContainer'/*, this.videoContainer*/);
        const gesture: Gesture = this.gestureCtrl.create(
            {
                el: this.videoContainer.nativeElement,
                threshold: 20,
                gestureName: 'swipe-gesture',
                onEnd: (ev) => this.swipeEvent(ev),
            },
            true
        );
        gesture.enable(true);
    }

    /**
     * Navigates back to component that opened video player page
     */
    navigateBack() {
        const canGoBack = this.appComponent.routerOutlet.canGoBack();
        this.logger.debug(`navigateBack - backRoute=${this.backRoute}, can go back: ${canGoBack}`);
        let backRoute: string;
        if (this.backRoute) {
            // This overrides other options
            backRoute = this.backRoute;
        } else if (canGoBack) {
            // If the router can go back, call pop()
            this.appComponent.routerOutlet.pop();
            return;
        } else if (this.class_id) {
            if (this.initialTimeInVideo || this.maxLoops > 1) {
                backRoute = `word-catalog-cards/${this.class_id}`;
            } else {
                backRoute = `${this.appData.authenticatedUser.role}-class/details/${this.class_id}`;
            }
        } else if (this.initialTimeInVideo || this.maxLoops > 1) {
            backRoute = 'word-catalog-cards';
        } else if (this.tag) {
            backRoute = `catalog/t/${this.tag}`;
        } else if (this.subjectSlug) {
            backRoute = `catalog/s/${this.subjectSlug}`;
        } else {
            backRoute = 'catalog';
        }

        this.navCtrl.navigateBack(backRoute);
    }

    /**
     * Redirect user if not allowed to view video based on license
     */
    checkLicense(video: IVideo) {
        if (this.tag) {
            // If user comes from a tag, allow (COVID-19)
            this.logger.debug('Video is allowed because of tag', this.tag);
            return true;
        }
        if (this.appData.isLoggedIn()) {
            if (!this.class_id) {
                const sourceInfo = this.appData.videoSources.find(
                    (ws) => ws.id === video.websource_id
                );
                const licenseForAll = (this.appData.videoSources || []).filter(ws => ws.licenseForAll).map(ws => ws.id);

                const websourceIdsInLicense = this.appData.userLicense?.websources as string[] || [];
                const allowedWebsourceIds = websourceIdsInLicense.concat(licenseForAll);
                if (
                    !allowedWebsourceIds.includes(video.websource_id) ||
                    this.appData.isStudent() && sourceInfo.isScooling
                ) {
                    this.translate
                        .get('not_allowed_websource')
                        .subscribe({ next: i18n => this.uiUtils.displayToast(i18n) });
                    this.navCtrl.navigateRoot('catalog');
                    return false;
                }
            }
        } else {
            // Only ORF allowed for guests
            const { isAllowed } = this.appManager.isWebsourceIdAllowedForAnonymousUser(
                video.websource_id
            );
            if (!isAllowed) {
                this.translate
                    .get('not_allowed_websource')
                    .subscribe({ next: i18n => this.uiUtils.displayToast(i18n) });
                this.navCtrl.navigateRoot('catalog');
                return false;
            }
        }
        return true;
    }

    /**
     * Initializes video
     */
    initVideo() {
        if (!this.videoInfo) {
            // this.logger.debug('initVideo - not videoInfo - returning');
            return;
        }

        this.checkLicense(this.videoInfo);

        const videoQuality = this.appData.getPreferenceString(
            this.appData.constants.pref.VIDEO_QUALITY,
            this.appData.constants.DefaultVideoQuality
        );
        const videoPlaybackUrl = this.getVideoUrlForQuality(videoQuality);
        // const videoPlaybackUrl = {
        //     type: 'application/x-mpegURL',
        //     protocol: 'https',
        //     resolution: 'medium',
        //     link: 'https://ndrod-vh.akamaihd.net/i/ndr/2020/0331/TV-20200331-2242-1000.,lo,hi,hq,hd,.mp4.csmil/master.m3u8'
        // };

        // this.logger.debug('Loading video url ' + videoPlaybackUrl.link);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisPage = this;

        // This seems to fix the issue that playbackRate is not working in Chrome on Android
        // See https://github.com/videojs/videojs-contrib-hls/issues/1157 and https://gitlab.com/uugotitTeam/webapp/issues/141
        // if (this.plt.is('android')) {
        videojs.options.hls.overrideNative = true;

        if (this.plt.is('ios') && this.plt.is('mobileweb') && videoPlaybackUrl.type === 'video/mp4' && this.clippingStart > 0) {
            // Clipping (setting initial time in video) doesn't work on iOS with MP4 videos
            // See https://gitlab.com/uugotitTeam/uugotit-webapp2/-/issues/124

            this.logger.debug(
                `Clipping (setting initial time in video) doesn't work on iOS with MP4 videos`
            );
            this.maxLoops = 1;
            this.clippingStart = -1;
            this.clippingEnd = -1;
            this.autoStart = false;
        }

        // We don't show a poster if a clip if the video is being looped, because this can be confusing for the user
        const options: any = {
            poster: this.initialTimeInVideo || this.maxLoops > 1 ? null : this.videoInfo.imageURL,
            controls: true,
            controlBar: {
                fullscreenToggle: !this.plt.is('ios'),
                pictureInPictureToggle: false
            },
            preload: 'auto',
            language: this.appData.getLanguage(),
            html5: {
                // See https://github.com/videojs/video.js/blob/master/docs/guides/text-tracks.md#emulated-text-tracks
                // If this is not set to false, then a "CC" button will be shonw on iOS
                nativeTextTracks: false
            },
        };

        this.isYouTubeVideo = videoPlaybackUrl.type === 'video/youtube';
        if (this.isYouTubeVideo) {
            options.techOrder = ['youtube'];
            // sources: [{ type: 'video/youtube', src: 'https://www.youtube.com/embed/CbcYj0BMKOI?showinfo=0&enablejsapi=1&origin=http://localhost:8100' }],
            // sources: [{ type: 'video/youtube', src: 'https://www.youtube.com/watch?v=tVt0W_BnwqY?showinfo=0&origin=http://localhost:8100' }],
            options.sources = { src: videoPlaybackUrl.link, type: videoPlaybackUrl.type };
            options.youtube = {
                ytControls: 0,
                showinfo: 0,
                rel: 0,
                modestbranding: 1,
                enablePrivacyEnhancedMode: true,
                iv_load_policy: 3,
                playsinline: 1,
            }; // Note: The rel parameter doesn't seem to work any more (https://developers.google.com/youtube/player_parameters?hl=de)
        }

        // A function that will be passed as a callback to videojs() initialization
        // isUpdate should be set to true if this NOT the first intialization of the video.js player
        const readyFn = function(isUpdate = false) {
            thisPage.logger.debug(
                `videojs player is ready. Supports fullscreen? ${this.supportsFullScreen()}`
            );
            const link = videoPlaybackUrl.link;
            // Alternative workaround for https://gitlab.com/uugotitTeam/uugotit-webapp2/-/issues/132
            // But we use src/ngsw-worker-patched.js instead that checks for 'range' headers.
            // if (link.endsWith('.mp4')) {
            //     thisPage.logger.debug('Video file is .mp4, adding ngsw-bypass');
            //     link = `${link}?ngsw-bypass=1`;
            // }

            if (!this.isYouTubeVideo) {
                this.src({
                    src: link,
                    type: videoPlaybackUrl.type,
                });
            }

            // this.src(thisPage.videoInfo.sources.map((videoInfo) => {
            //     return {
            //         src: videoInfo.link,
            //         type: videoInfo.type
            //     };
            // }));

            // this.src({
            //     src: 'https://www.youtube.com/watch?v=kkGeOWYOFoA',
            //     type: 'video/youtube',
            // });

            // Check if the video already has the translation:
            const hasTranslation = thisPage.videoInfo.translations?.includes(thisPage.language);

            const isIOsWeb = thisPage.isIOsWeb; // hybrid: a device running Capacitor or Cordova
            thisPage.logger.debug(
                `isIOsWeb: ${isIOsWeb}, is ios: ${thisPage.plt.is(
                    'ios'
                )}, is hybrid: ${thisPage.plt.is('hybrid')}`
            );
            if (
                (thisPage.initialTimeInVideo || thisPage.maxLoops > 1) &&
                !isIOsWeb && // There is an issue on Safari on the iPhone where the video doesn't start automatically
                hasTranslation // Only hide the play button if the video already has a translation - otherwise the user needs to wait and manually start
            ) {
                thisPage.logger.debug('Hiding big play button');
                this.bigPlayButton.hide();
            }

            if (!thisPage.showVideoJsBigPlayButton) {
                // Override
                this.bigPlayButton.hide();
            }

            if (!thisPage.isYouTubeVideo) {
                this.defaultPlaybackRate(thisPage.playbackRate); // Seems to throw an error with the YouTube tech
            }
            this.playbackRate(thisPage.playbackRate);

            // Setting player's volume and muted status from preferences
            const volume = Number.parseFloat(
                thisPage.appData.getPreferenceString(thisPage.constants.pref.PLAYER_VOLUME, '1')
            );
            const muted =
                thisPage.appData.getPreferenceString(thisPage.constants.pref.PLAYER_MUTED, '0') ===
                '1';
            this.volume(volume);
            this.muted(muted);
            thisPage.logger.debug(`Setting volume: ${volume} to and muted: ${muted}`);

            thisPage.isFirstPlayEventForVideo = true;
            thisPage.isFirstEndEventForVideo = true;

            thisPage.logger.debug(
                `Init video offset, start: ${thisPage.videoOffsetStart}, end: ${thisPage.videoOffsetEnd}`
            );
            thisPage.updateVideoOffset();

            if (isUpdate) {
                thisPage.logger.debug('###### ready function is update, return here');
                return;
            } else {
                thisPage.logger.debug('###### ready function is initial, continue');
            }

            // ### START: HTML elements setup

            // We have to move the video-subtitle div to within the main_video div so that it will be when the player is in full screen mode:
            // Note: The order of the elements is important (ones up in the list will be overlaid by one lower down)
            const elementIds = [
                'video-subtitle',
                'video-postroll',
                'video-overlay-buttons-container',
                'video-overlay-center-container',
                'video-overlay-middleright-container',
                'video-overlay-topleft-container',
                'video-overlay-hide-burned-subtitles',
                'video-postroll3-playnext',
                'video-player-settings',
                'video-player-leaderboard-overlay',
                'video-player-educator-overlay-component',
                'video-player-task-overlay-component',
                'video-overlay-intro2',
                'video-player-click-delay-progress',
                'video-overlay-bottomleft-container'
                // 'video-overlay-bottom-insert'
            ];
            const mainVideoElement = document.getElementById('main_video');

            elementIds.forEach((elementId) => {
                const element = document.getElementById(elementId);
                if (element) {
                    if (elementId === 'video-overlay-hide-burned-subtitles') {
                        mainVideoElement.insertBefore(
                            element,
                            document.getElementsByClassName('vjs-control-bar')[0]
                        );
                    } else {
                        mainVideoElement.appendChild(element);
                    }
                }
            });
            // mainVideoElement.appendChild(bottomInsertContainer);

            // ### START: Player events handling

            this.on('timeupdate', () => {
                thisPage.onPlayerTimeUpdate();

                const adOverlay = document.getElementById('ad-overlay');
                const adVideo = adOverlay.querySelector('video');
                if (thisPage.videoJSplayer && thisPage.videoInfo.isFromReachAll) {
                    const currentTime = thisPage.videoJSplayer.currentTime();
                    const duration = thisPage.videoJSplayer.duration();
                    if (!this.adShown && currentTime >= duration / 2) {
                        thisPage.adDuration = adVideo.duration;
                        this.adShown = true;
                        thisPage.videoJSplayer.pause();
                        adOverlay.style.display = 'flex';
                        const controlBar = thisPage.videoJSplayer.controlBar;
                        if (controlBar) {
                            controlBar.hide();
                        }
                        if (thisPage.videoJSplayer.isFullscreen()) {
                            this.exitFullscreen();
                        }
                        adVideo.play();
                        setInterval(() => {
                            thisPage.adDuration -= 1;
                        }, 1000)
                        adVideo.onended = () => {
                            if (controlBar) {
                                controlBar.show();
                            }
                            adOverlay.style.display = 'none';
                            thisPage.videoJSplayer.play();
                        };
                    }
                }
            });

            this.on('play', () => {
                thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'play', thisPage.language, thisPage.videoDuration);
                thisPage.lastAnalyticsPlayingEvent = moment();
                thisPage.logger.debug(
                    `=== VIDEO play currentTime=${thisPage.videoJSplayer.currentTime()}, duration (clip)=${
                        thisPage.videoDuration
                    } duration (full)=${thisPage.videoInfo.duration}`
                );
                if (thisPage.isFirstPlayEventForVideo) {
                    thisPage.isFirstPlayEventForVideo = false;
                    thisPage.onePlayerPlay();
                    thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'play_one', thisPage.language, thisPage.videoDuration);
                    thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'play_one_category_id', thisPage.videoInfo?.cat_id);
                    thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'play_one_websource_id', thisPage.videoInfo?.websource_id);
                    thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'play_one_program', thisPage.videoInfo?.program);
                    const quality = thisPage.appData.getPreferenceString(
                        thisPage.appData.constants.pref.VIDEO_QUALITY,
                        'default' // thisPage.appData.constants.DefaultVideoQuality
                    );
                    thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'play_one_video_quality', quality);
                }
                thisPage.onPlayerPlay();
            });

            this.one('play', () => {
                // We call the onePlayerPlay() in the 'play' event instead because otherwise this event would
                // not be triggered once we re-initialize the video.js player with a new video.
                // In that case, event listeners will not be added again, otherwise they would be called multiple times.
            });

            this.on('pause', () => {
                thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'pause');
                // We need to round the times otherwise it might happend that they are not exactly equal like: 7.499998999999999 and 7.499999999999998
                const currentTimeRounded = _.round(thisPage.videoJSplayer.currentTime(), 2);
                const videoDurationRounded = _.round(thisPage.videoDuration, 2);
                const remainingTime = _.round(thisPage.videoJSplayer.remainingTime(), 2);
                thisPage.logger.debug(
                    `=== VIDEO paused currentTime=${currentTimeRounded}, videoDuration (clip)=${videoDurationRounded} duration (full)=${thisPage.videoInfo.duration}, remainingTime=${remainingTime}`
                );
                thisPage.logger.debug(
                    `=== VIDEO paused maxLoops=${thisPage.maxLoops} loopCounter=${thisPage.loopCounter}`
                );
                if (currentTimeRounded === videoDurationRounded) {
                    // We do this here as a workaround because the 'ended' event seems to get called only once
                    thisPage.checkLoopVideo();
                }
                thisPage.onPlayerPause();
            });

            this.one('ended', () => { // TODO
                thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'ended_one');
                // thisPage.logger.debug(`=== VIDEO ended (once), maxLoops=${this.maxLoops} loopCounter=${this.loopCounter}`);

                // We call the onePlayerEnd() in the 'ended' event instead because otherwise this event would
                // not be triggered once we re-initialize the video.js player with a new video.
                // In that case, event listeners will not be added again, otherwise they would be called multiple times.
            });

            this.on('ended', () => { // TODO
                // thisPage.logger.debug(`=== VIDEO ended, maxLoops=${this.maxLoops} loopCounter=${this.loopCounter}`);
                thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'ended');
                if (thisPage.isFirstEndEventForVideo) {
                    thisPage.isFirstEndEventForVideo = false;
                    thisPage.onePlayerEnd();
                }
                thisPage.onPlayerEnd();
            });

            this.on('seeking', () => { // TODO
                // thisPage.logger.debug(`=== VIDEO seeking`);
                thisPage.lastTimeSeeking = new Date().getTime();
            });

            // let videojs_player = this;
            // videojs_player.on('click', function(event) {
            //   thisPage.logger.debug('click', event.clientX, event.clientY, this.currentTime());
            //   event.preventDefault();
            //   return false;
            // });

            // videojs_player.on('touchstart', function(event) {
            //   thisPage.logger.debug('touchstart', event.clientX, event.clientY, this.currentTime());
            //   event.preventDefault();
            //   return false;
            // });

            // Function to set the intial time in the video
            const setInitialTimeInVideo = () => {
                thisPage.logger.debug(
                    'videojs callback thisPage.initialTimeInVideo',
                    thisPage.initialTimeInVideo
                );
                if (thisPage.initialTimeInVideo) {
                    const time = thisPage.initialTimeInVideo;
                    thisPage.logger.debug('Setting initial time in video (incl. offset) to ', time);
                    this.currentTime(time);
                } else if (
                    thisPage.clip &&
                    thisPage.clip?.createdBy === thisPage.appData.authenticatedUser?._id &&
                    !thisPage.initialTimeInVideo
                ) {
                    thisPage.logger.debug(
                        'Setting initial time in video (clip) to ',
                        thisPage.clip.start
                    );
                    if (thisPage.clip.cropStart) {
                        const start = thisPage.clip.start - thisPage.clip.cropStart;
                        this.currentTime(start);
                    } else {
                        this.currentTime(thisPage.clip.start);
                    }
                }
            };

            // Function to check if initial time needs to be set
            // and if video playback should be started automatically
            const checkInitialTimeAndAutoStart = () => {
                if (!thisPage.initialTimeInVideoInitDone) {
                    setInitialTimeInVideo();
                    thisPage.initialTimeInVideoInitDone = true;
                }

                // If we have autoStart or initialTimeInVideo plus the translation ready, play the video:
                if (!thisPage.autoStartExecuted && (thisPage.autoStart || thisPage.initialTimeInVideo) && hasTranslation) {
                    thisPage.logger.debug(`autoStart is true, playing video`);
                    thisPage.autoStart = false;
                    thisPage.autoStartExecuted = true;
                    this.play();
                }
            };

            this.on('useractive', (event) => {
                thisPage.logger.debug('player useractive event'/*, event*/);
                // event.preventDefault();
                // event.stopImmediatePropagation();
                thisPage.lastTimeUserActive = moment();
                const now = new Date();
                if (thisPage.lastWordClickedTime + 300 > now.getTime()) {
                    thisPage.logger.debug('Hiding ControlBar userActive(false)');
                    this.userActive(false);
                }
            });

            this.on('userinactive', (event) => {
                thisPage.logger.debug('player userinactive event'/*, event*/);
            });

            this.on('fullscreenchange', (event) => {
                thisPage.logger.debug(`full screen change ${this.isFullscreen()}`);
                thisPage.isVideoFullscreen = this.isFullscreen();
                if (thisPage.isVideoFullscreen) {
                    thisPage.appComponent.hideStatusBar();
                } else {
                    if (!thisPage.screenOrientation.type.startsWith('landscape')) {
                        // Keep status bar hidden if orientation is landscape
                        thisPage.appComponent.showStatusBar();
                    }
                }
            });

            // wait for video metadata to load, then set time
            // Event: 'loadedmetadata': The user agent has just determined the duration and dimensions of the media resource and the text tracks are ready.
            this.on('loadedmetadata', (event) => {
                thisPage.logger.debug('player loaded metadata'/*, event*/);
                thisPage.isVideoMetadataLoaded = true;
                thisPage.showLoadingProgress = false;
                if (!isIOsWeb) {
                    // Don't do this on iOS yet, do it on the 'canplaythrough' event instead
                    checkInitialTimeAndAutoStart();
                }
                // This needs to be called here, because for adding the tasks to the progress bar, the video duration is required
                thisPage.loadTasks();
                // Initialize empty clip
                // if (!this.clip) {
                //     this.clip = {
                //         video_id: thisPage.videoInfo._id,
                //         start: 0,
                //         end: this.duration(),
                //         duration: this.duration(),
                //         createdBy: thisPage.appData.authenticatedUser._id,
                //         isFull: true,
                //         task_ids: [],
                //         title: null,
                //     };
                // }
            });

            // This event comes after 'canplaythrough'
            // iPhone/iPad need to play first, then set the time
            // events: https://www.w3.org/TR/html5/embedded-content-0.html#mediaevents
            // Event 'canplaythrough': The user agent has just determined the duration and dimensions of the media resource and the text tracks are ready.
            this.on('canplaythrough', (event) => {
                thisPage.logger.debug('player can play through'/*, event*/);
                checkInitialTimeAndAutoStart();
            });


            // Listen for 'volumechange' event
            this.on('volumechange', (event) => {
                if (thisPage.videoJsEventsEnabled.volumechange) {
                    thisPage.logger.debug(`player volume changed: ${this.volume()}, muted: ${this.muted()}`);
                    thisPage.onPlayerVolumeChange();
                } else {
                    thisPage.logger.debug(`DISABLED player volume changed: ${this.volume()}, muted: ${this.muted()}`);
                }
            });
            // If we have an initial time, play the video.
            // This doesn't work in Safari on iOS due to limitations
            // if (thisPage.initialTimeInVideo) {
            //     this.play();
            // }
        };

        if (!this.videoJSplayer) {
            // Create a new videojs object
            this.videoJSplayer = videojs(document.getElementById('main_video'), options, readyFn);
        } else {
            // Oh, we already got a videojs object - we need to re-initialize it

            // Disabled the 'volumechange' event temporarily, because reset() would otherwise reset the volume to 1,
            // and this would be stored in the preferences of the user:
            this.videoJsEventsEnabled.volumechange = false;
            this.videoJSplayer.reset();
            this.videoJsEventsEnabled.volumechange = true;
            setTimeout(() => {
                // this.videoJSplayer.poster(options.poster);
                this.videoJSplayer.poster(null);
                // readyFn must be bound to videojs player object because it uses `this` to reference the object
                readyFn.bind(this.videoJSplayer)(true);
            }, 300);
        }
    }

    /**
     * Loads a new video that replaces the current one.
     *
     * @param video the video object
     * @param autoStart whether video should automatically start
     * @param hasSubtitles does the video have subtitles?
     */
    initNewVideo(video: IVideo, autoStart = false, hasSubtitles: boolean) {
        this.logger.debug('initNewVideo', video._id, autoStart, hasSubtitles);
        this.maxLoops = 1;
        this.loopCounter = 0;
        this.clippingStart = -1;
        this.clippingEnd = -1;
        this.autoStart = autoStart && hasSubtitles; // We enable autostart only if subtitles are available
        this.autoStartExecuted = false;
        this.initialTimeInVideo = null;
        this.isVideoMetadataLoaded = false;
        this.isSubtitlesLoaded = false;
        this.wordsClicked = [];
        this.videoPlayerTaskOverlay?.initNewVideo();

        this.videoInfo = video;
        this.clip = null;
        this.translation = null;
        this.showLoadingProgress = autoStart;
        this.checkLicense(this.videoInfo);
        this.initVideo();
        this.loadSubtitles();
        this.loadFavoredVideo();
        this.updateHtmlMeta();
        const websource = this.appData.getWebsourceById(this.videoInfo.websource_id);
        this.isShareEnabled = websource && !websource.isScooling;

        if (autoStart && hasSubtitles) {
            this.logger.debug('initNewVideo hiding bigPlayButton');
            this.videoJSplayer.bigPlayButton.hide();
        } else if (this.showVideoJsBigPlayButton) {
            this.logger.debug('initNewVideo showing bigPlayButton');
            this.videoJSplayer.bigPlayButton.show();
        }

        // Update path in URL
        window.history.replaceState({}, '', `/video/${this.videoInfo.websource_id}/${this.videoInfo._id}`);
    }

    /**
     * This function should be called on the video "ended" event and will check
     * if the video clip needs to be re-started (looped).
     */
    checkLoopVideo() {
        if (this.maxLoops > 1 && this.loopCounter < this.maxLoops) {
            this.logger.debug(`Loop counter is now ${this.loopCounter}. Starting loop again`);
            ++this.loopCounter;
            setTimeout(() => {
                this.videoJSplayer.currentTime(0);
                if (this.loopCounter < this.maxLoops) {
                    // Only start playing again, if it's not the last loop
                    this.logger.debug('Starting loop again');
                    this.videoJSplayer.play();
                }
            }, 600);
        }
    }

    /**
     * Load the subtitles from the server API.
     */
    async loadSubtitles(forceUpdateSubtitles = false) {
        // let translationType = this.appData.getPreferenceString(this.constants.pref.TRANSLATION_TYPE, this.appData.constants.DefaultTranslationType);


        // ### This is what will be executed after the subtitles have been loaded:

        const doAfterLoaded = async () => {
            this.isSubtitlesLoaded = true;
            if (forceUpdateSubtitles) {
                // This will immediately recreate the currently shown subtitles (for example if language was changed)
                this.currentChunkIndex = -1;
                this.onPlayerTimeUpdate();
            }
            if (this.introAnimation && this.introAnimation.isShown && this.videoInfo.hasSubtitle) {
                // Show a random subtitle from the video
                const introIdx = this.findSubtitleForIntroAnimation(this.translation);
                this.introAnimation.subtitleIndex = introIdx;
                this.logger.debug(`doAfterLoaded chunkIndex: ${introIdx.chunkIndex}, wordIndex: ${introIdx.wordIndex}`);
                this.updateSubtitle(introIdx.chunkIndex, this.translation.subtitles[introIdx.chunkIndex]);
            }

            // If we have autoStart or initialTimeInVideo plus the translation ready, play the video:
            if (!this.autoStartExecuted && (this.autoStart || this.initialTimeInVideo) && (this.translation || !this.videoInfo.hasSubtitle)) {
                this.logger.debug(`loadSubtitles - autoStart is true, playing video`);
                this.autoStart = false;
                this.autoStartExecuted = true;
                await this.checkLandscapeFullscreen();
                this.videoJSplayer.play();
            } else {
                this.logger.debug(`loadSubtitles - no autoStart, autoStartExecuted=${this.autoStartExecuted}, autoStart=${this.autoStart}`);
            }
            this.loadTasks();
        };

        if (!this.videoInfo.hasSubtitle) {
            this.logger.debug('loadSubtitles() - video has no subtitles');
            doAfterLoaded();
            return;
        }

        // ### Prepare the waiting (progress) dialog:

        const videoId = this.videoInfo._id;
        // const message = 'wait_video_translation';
        const messageCode = `wait_video_translation_`; //${Math.ceil(Math.random() * 4.0)}
        const waitingMessage = this.translate.instant(`${messageCode}1`, {
            language: this.translate.instant('lang_' + this.language),
        });
        const waitingMessageTemplate = `<div class="text-message">{{message}}</div><div class="text-percent">{{percent}}%</div>`;
        this.subtitleLoadingDialog = await this.loadingCtrl.create({
            spinner: 'circles', //'dots', // See https://ionicframework.com/docs/api/loading#properties
            cssClass: 'video-translation-wait-dialog',
            message: waitingMessageTemplate
                .replace('{{message}}', waitingMessage)
                .replace('{{percent}}', '0'),
        });

        // ### Now load the subtitles form the API:

        if (this.videoInfo && this.language === this.videoInfo.originalLang && this.language === 'en') {
            this.language = this.appData.constants.DefaultTranslationLangFallbackEn;
        }

        this.translationsApi.getSubtitlesForVideo(videoId, this.language).subscribe(
            (response) => {
                this.logger.debug(
                    `getSubtitlesForVideo got ${
                        response.data && response.data.subtitles
                            ? response.data.subtitles.length
                            : 'no'
                    } text chunks `
                );
                if (response.success) {
                    this.translation = response.data ? response.data : null;
                    doAfterLoaded();
                } else {
                    // Start translation if translation document doesn't exist
                    const waitingStartTime = Date.now();
                    this.subtitleLoadingDialog.present();

                    // const options: ITranslationOptions = {
                    //     from: 'de',
                    //     to: this.language,
                    //     preprocessText: true,
                    //     preprocessWords: true,
                    //     processWithAlignment: false,
                    //     process: 'msTranslator',
                    //     processText: false,
                    //     postprocessText: false,
                    //     postprocessWords: true,
                    // };
                    const options: ITranslationOptions = this.defaultTranslationOptions;

                    const videoOriginalLang = this.videoInfo.originalLang;
                    if (videoOriginalLang && videoOriginalLang === 'en') {
                        options.from = 'en';
                    }

                    this.translationsApi
                        .translateSingleVideo(
                            this.videoInfo.websource_id,
                            options,
                            this.videoInfo._id,
                            false,
                            true,
                            false
                        )
                        .subscribe(
                            (res) => {
                                this.logger.debug('Translation finished (long running request): ', res);
                            },
                            (err) => {
                                this.logger.error('Error starting translation: ', err);
                            }
                        );
                    this.checkVideoTranslatedInterval(doAfterLoaded, waitingStartTime, options, messageCode, waitingMessageTemplate);
                }

                // setTimeout(() => {
                //   this.textChunks = data;
                // }, 5000)
                // console.dir(data);

                // loader.dismiss().then(() => {
                //   this.appData.videos = data;
                // });
            },
            (err) => {
                // loader.dismiss().then(() => {
                this.logger.error(`Error reloading data: ${err}`);
                // this.uiUtils.showErrorAlert('Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Fehlercode: ' + err.status + ' ' + err.statusText, null,
                //   () => {
                //     this.logger.debug('user wants to reload');
                //     this.loadSubtitles();
                //   }
                // );

                // });
            },
            () => this.logger.debug('Loading subtitles is complete')
        );
    }

    /**
     * Load the tasks from the server API and sets clip markers.
     */
    async loadTasks() {
        if (!this.isVideoMetadataLoaded || !this.isSubtitlesLoaded || this.videoPlayerTaskOverlay.tasks) {
            // Both video metadata and subitles need to be loaded
            return;
        }
        if (!this.clip) {
            return;
        }
        try {
            this.logger.debug('Clip:', this.clip);
            const isClipByCurrentUser = this.appData.isEducator() && this.clip.createdBy === this.appData.authenticatedUser?._id;
            const onlyVisible = !isClipByCurrentUser;
            let tasksResponse;
            if (this.appData.isLoggedIn()) {
                tasksResponse = await this.clipsApi.getTasksByClipId(this.clip._id, 0, 200, null, onlyVisible).toPromise();
            } else {
                tasksResponse = await this.clipsApi.getTasksByClipIdForAnonymousUser(this.clip._id, 0, 200, null, onlyVisible).toPromise();
            }
            const tasks = tasksResponse.data;
            this.logger.debug('Got tasks for clip:', tasks);
            this.videoPlayerTaskOverlay.tasks = tasks;
            if (tasks) {
                if (this.translation && !isClipByCurrentUser) {
                    // Calculate the duration of the video
                    const maxTime = this.videoJSplayer.duration() as number + this.videoOffsetStart;
                    this.videoPlayerTaskOverlay.moveTasksToEndOfSubtitleFrames(tasks, this.translation, maxTime);
                }
                const cropOffset = 0; //this.clip.cropStart ? this.clip.cropStart : 0;
                const markers = tasks.map((t) => {
                    return { time: t.position - cropOffset, label: t.question, id: t._id };
                });
                this.addMarkersToProgressBar(markers);
            }
            if (isClipByCurrentUser) {
                // Set the clip markers (only for educator who created the clip)
                this.setClipMarkers(this.clip.start, this.clip.end, this.clip.cropStart, this.clip.cropEnd);
                this.videoJSplayer.controlBar.show(); // That seems to have no effect
                // this.logger.debug(`ControlBar is ${this.videoJSplayer.controlBar}`);
                this.logger.info(this.videoJSplayer.controlBar);
            }
        } catch (err) {
            this.logger.error(`Error loading tasks: ${err}`);
        }

    }

    private checkVideoTranslatedInterval(doAfterLoaded: () => void, waitingStartTime: number, options: ITranslationOptions, messageCode: string, waitingMessageTemplate: string) {
        const translationStartTime = moment();
        // Check if video translated every 3 seconds
        this.translationSubscription = interval(1500).subscribe((transInterval: number) => {
            this.translationsApi
                .getSubtitlesForVideo(this.videoInfo._id, this.language)
                .subscribe(async (subResponse) => {
                    this.logger.debug('Got subtitles for video:', subResponse);
                    // If translation done
                    if (subResponse.success) { // We need to check for translation, because it could overlap with the previous request
                        if (!this.translation || subResponse.data.options.to !== this.translation.options.to) {
                            this.logger.info(`Got subtitle (was not yet loaded or different language at time ${transInterval}`);
                            this.translationSubscription.unsubscribe();
                            this.videoInfo.translations.push(this.language);
                            this.translation = subResponse.data ? subResponse.data : null;
                            // this.initVideo();
                            // this.appData.forceReloadVideos(); // This is not needed any more, because the translation is always queried when entering the video player page
                            const videos = this.appData?.videos;
                            if (videos) {
                                const videoInAppData = videos.find(v => v._id === this.videoInfo._id);
                                if (videoInAppData) {
                                    this.logger.debug('Got video in appData with translations:', videoInAppData.translations);
                                    if (videoInAppData.translations && !videoInAppData.translations.includes(this.language)) {
                                        videoInAppData.translations.push(this.language);
                                    }
                                    this.logger.debug('Got video in appData (after adding translation) with translations:', videoInAppData.translations);
                                }
                            }
                            this.subtitleLoadingDialog.dismiss();
                            this.subtitleLoadingDialog = null;
                            doAfterLoaded();
                        } else {
                            this.logger.info(`Got subtitle but it was already loaded at time ${transInterval}`);
                        }
                    } else {

                        const secondsElapsed = moment().diff(translationStartTime, 'seconds');
                        this.logger.debug(`Translation has been running since ${secondsElapsed} seconds`);
                        if (secondsElapsed > 15 * 60) {
                            this.uiUtils.showInfoAlert(
                                this.translate.instant('translation_timeout_header'),
                                null,
                                this.translate.instant('translation_timeout_message'),
                                () => {
                                    this.navigateBack();
                                }
                            );
                            this.translationSubscription.unsubscribe();
                            this.subtitleLoadingDialog.dismiss();
                            this.subtitleLoadingDialog = null;
                            if (this.plt.is('capacitor')) {
                                FirebaseCrashlytics.recordException({
                                    message: `Translation timeout (${secondsElapsed} sec.) for video ${this.videoInfo._id}, language ${options.to}`,
                                });
                            }
                            return;
                        }

                        // Update the waiting dialog (message and percent) - texts 1 to 4 loop through every 7 seconds
                        let percent: string;
                        if (subResponse.translationProgress !== undefined && subResponse.translationProgress !== null) {
                            percent = Math.round(subResponse.translationProgress * 100).toString();
                        } else {
                            percent = '- ';
                        }
                        const secondsSinceStart = (Date.now() - waitingStartTime) / 1000;
                        const changeTextEverySec = 8;
                        const numOfTexts = 4;
                        const step = secondsSinceStart / changeTextEverySec;
                        const num = Math.ceil(step % numOfTexts);
                        this.logger.debug(`percent=${percent} secondsSinceStart=${secondsSinceStart}, step=${step}, num=${num}`);
                        const waitingMessageNew = this.translate.instant(`${messageCode}${num}`, {
                            language: this.translate.instant('lang_' + this.language),
                        });
                        this.subtitleLoadingDialog.message = waitingMessageTemplate
                            .replace('{{message}}', waitingMessageNew)
                            .replace('{{percent}}', percent);
                    }
                });
        });
    }

    async loadFavoredVideo() {
        if (!this.appData.isLoggedIn()) {
            return;
        }
        try {
            const videoId = this.videoInfo._id;
            const favoredData = await this.favoredVideosApi.listByVideos([videoId]).toPromise();
            this.favoredVideo = favoredData?.data?.length ? favoredData.data[0] : null;
        } catch (err) {
            this.logger.error('loadFavoredVideo() Error:', err);
        }
    }

    /**
     * Updates the offset of the video (using the plugin https://github.com/cladera/videojs-offset/)
     */
    updateVideoOffset() {
        const start = this.videoOffsetStart;
        const end = this.videoOffsetEnd;
        this.logger.debug('updateVideoOffset', start, end);
        this.videoJSplayer.offset({
            start,
            end,
            restart_beginning: false, // Should the video go to the beginning when it ends
        });
    }

    get videoOffsetStart() {
        if (!this.videoInfo) {
            return 0;
        }
        // this.logger.debug('clippingStart', this.clippingStart);
        if (this.clippingStart > -1) {
            // We have a clipping
            return this.clippingStart;
        } else if (this.clip && this.appData.authenticatedUser?._id === this.clip.createdBy && !_.isNil(this.clip.cropStart)) {
            // this.logger.warn('+++++ videoOffsetStart', this.clip.cropStart);
            // Educator who created the task
            return this.clip.cropStart;
        } else if (this.clip && this.appData.authenticatedUser?._id !== this.clip.createdBy && this.clip.start > 0) {
            // Student
            return this.clip.start;
        } else if (this.videoInfo.offset && this.videoInfo.offset.length === 2) {
            // We have an offset
            return this.videoInfo.offset[0];
        } else {
            return 0;
        }
    }

    get videoOffsetEnd() {
        if (!this.videoInfo) {
            return 0;
        }
        // this.logger.debug('clippingEnd', this.clippingEnd);
        if (this.clippingEnd > -1) {
            // We have a clipping
            return this.clippingEnd;
        } else if (this.clip && this.appData.authenticatedUser?._id === this.clip.createdBy && !_.isNil(this.clip.cropEnd)) {
            // this.logger.warn('+++++ videoOffsetEnd', this.clip.cropEnd);
            return this.clip.cropEnd;
        } else if (this.clip && this.appData.authenticatedUser?._id !== this.clip.createdBy && (this.clip.end < this.videoInfo.duration || _.isNil(this.videoInfo.duration))) {
            // Note: For Planet Schule videos th "duration" property is undefined
            return this.clip.end;
        } else if (this.videoInfo.offset && this.videoInfo.offset.length === 2) {
            // We have an offset
            return this.videoInfo.offset[1];
        } else {
            return this.videoInfo.duration;
        }
    }

    /**
     * Duration of the video in seconds.
     */
    get videoDuration() {
        return this.videoOffsetEnd - this.videoOffsetStart;
    }

    /**
     * Callback for the 'timeupdate' event of the videojs player
     */
    onPlayerTimeUpdate() {
        try {
            // Needed for syncing the subtitles because subtitles are synced with the original time in the video
            const newTime = this.videoJSplayer.currentTime();

            // This is never defined now for some reason
            // if (this.videoJSplayer.currentOriginalTime) {
            //     newTime = this.videoJSplayer.currentOriginalTime(); // function only exist, if offset plugin was initiated.
            // }

            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const newTimeWithOffset = this.videoJSplayer.startOffset() + this.videoJSplayer.currentTime();

            // this.logger.debug(`+++++ Current time is: ${newTime}, with offset: ${newTimeWithOffset}`);
            this.updateWatchedVideo(newTime);

            this.currentTime = newTime;

            let foundChunk = false;
            if (this.translation) {
                for (let i = 0; i < this.translation.subtitles.length; i++) {
                    const chunk = this.translation.subtitles[i];
                    if (chunk.start < newTimeWithOffset && chunk.end > newTimeWithOffset) {
                        if (i !== this.currentChunkIndex) {
                            this.logger.debug(`Setting new subtitle with index ${i}`);
                            this.updateSubtitle(i, chunk);
                        }
                        foundChunk = true;
                        break;
                    }
                }
            } else {
                this.logger.debug('textChunks is null');
            }
            if (!foundChunk) {
                this.currentSubtitle = '';
                // this.destroyAllPopovers();
            }

            // ### Track "playing" event every 5 minutes
            if (this.lastAnalyticsPlayingEvent) {
                if (moment().diff(this.lastAnalyticsPlayingEvent, 'minutes') >= 5) {
                    this.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'playing');
                    this.lastAnalyticsPlayingEvent = moment();
                }
            }

            this.checkTaskAtCurrentTime();

        } catch (err) {
            this.logger.error('Error in onPlayerTimeUpdate', err);
        }
    }

    /**
     * Callback for the 'play' event of the videojs player
     */
    onPlayerPlay() {
        this.appData.playedVideoOfSource.set(this.videoInfo.websource_id, true);

        if (!this.adShown) {
            if (!this.isInitialPlay) {
                const currentTime = this.videoJSplayer.currentTime();
                const currentTimeRounded = _.round(currentTime, 2);
                const videoDurationRounded = _.round(this.videoDuration, 2);
                const remainingTime = _.round(this.videoJSplayer.remainingTime(), 2);
                const nowTime = new Date().getTime();
                const isSeeking = this.lastTimeSeeking && this.lastTimeSeeking > nowTime - 500;
                this.logger.debug(`onPlayerPlay - currentTime: ${currentTime}, currentTimeRounded: ${currentTimeRounded}, videoDuration: ${this.videoDuration}, seeking: ${isSeeking}, remainingTime: ${remainingTime}`);
                if (!isSeeking && remainingTime === 0) {
                    // Restart video from time 0 because it was at the end
                    this.logger.debug(`Restart video from time 0 (current time is ${currentTime} - rounded to ${currentTimeRounded})`);
                    this.videoJSplayer.currentTime(0);
                } if (!isSeeking && currentTime > 0.5 && remainingTime > 0.5 /*&& !this.clickDelayTimeout*/) {
                    this.videoJSplayer.currentTime(currentTime - this.goBackSecondsAfterResume);
                    this.logger.debug(
                        `on video play ${currentTime} sec, going back for ${this.goBackSecondsAfterResume} sec`
                    );
                } else {
                    this.logger.debug(`on video play ${currentTime} sec, not going back`);
                }
            }
        }
        this.isInitialPlay = false;

        this.showPostroll = false;

        this.clickDelay.isPausedInfinite = false;

        this.keepAwake('onPlayerPlay');
    }

    /**
     * Callback for the 'play' event of the videojs player (once)
     */
    onePlayerPlay() {

        // Lock to landscape mode

        const orientation = this.preferredLandscapeOrientation;
        this.screenOrientation.lock(orientation).then(
            (success) => this.logger.debug(`Sucessfully locked to landscape mode "${orientation}":`, success),
            (failure) => this.logger.debug(`Device could not be locked to landscape mode "${orientation}":`, failure)
        );

        this.updateWatchedVideo(0, true);

        if (this.appData.isStudent()) {
            const event: IActivityEvent = {
                installationID: this.appData.getInstallationId(),
                category: ActivityCategory.video,
                video_id: this.videoInfo._id,
                action: ActivityAction.video_started,
                label: this.videoInfo._id,
                generated_at: new Date(),
            };
            if (this.class_id) {
                event.group_id = this.class_id;
            }

            this.activityService.saveEventToStorage(event);
        }

        // ### Check if a) there is a "next" video and b) if it has a translation (if it doesn't start a translation process)
        if (this.isAutoPlayNextVideo) {
            this.videosApi.getPlayNext(this.videoInfo._id).subscribe(async (response) => {
                if (response.success) {
                    try {
                        this.logger.debug('onePlayerPlay got next video:', response.nextVideo);
                        const responseSubtitles = await this.translationsApi
                            .getSubtitlesForVideo(response.nextVideo._id, this.language)
                            .toPromise();
                        const hasSubtitles = responseSubtitles.data && responseSubtitles.data.subtitles;
                        this.logger.debug(
                            `onePlayerPlay next video got subtitles: ${
                                hasSubtitles ? responseSubtitles.data.subtitles.length : 'no'
                            } text chunks `
                        );
                        if (!responseSubtitles.success) {
                            this.logger.debug(
                                'Attempting to start translation for next video: ',
                                response.nextVideo._id
                            );
                            // No translation for the next video, start translation process
                            const res = await this.translationsApi
                                .translateSingleVideo(
                                    response.nextVideo.websource_id,
                                    this.defaultTranslationOptions,
                                    response.nextVideo._id,
                                    false,
                                    true,
                                    false
                                )
                                .toPromise();
                            this.logger.debug('Translation for next video started: ', res);
                        }
                    } catch (err) {
                        this.logger.debug('Error in getPlayNext response', err);
                    }
                } else {
                    this.logger.debug('onePlayerPlay - no next video');
                }
            });
        }

        this.videoPlayerTaskOverlay?.initialVideoPlayStarted();
    }

    /**
     * Callback for the 'pause' event of the videojs player
     */
    onPlayerPause() {
        this.allowSleepAgain('onPlayerPause');
        this.updateWatchedVideo(this.videoJSplayer.currentTime(), true);
    }

    /**
     * This is called only ONCE on the 'ended' event.
     */
    onePlayerEnd() {
        this.logger.debug(
            `onePlayerEnd authenticatedUser=${this.appData.authenticatedUser}, maxLoops=${this.maxLoops}, count wordsClicked=${this.wordsClicked.length}`
        );

        // Only do this if we a are not in a loop
        if (this.maxLoops === null || this.maxLoops <= 1) {
            if (this.appData.isStudent()) {
                const event: IActivityEvent = {
                    installationID: this.appData.getInstallationId(),
                    category: ActivityCategory.video,
                    video_id: this.videoInfo._id,
                    action: ActivityAction.video_ended,
                    label: this.videoInfo._id,
                    generated_at: new Date(),
                };
                if (this.class_id) {
                    event.group_id = this.class_id;
                }

                this.activityService.saveEventToStorage(event);
            }

        }
    }

    /**
     * This is called only on every 'ended' event.
     */
    onPlayerEnd() {
        this.logger.debug(
            `onPlayerEnd authenticatedUser=${this.appData.authenticatedUser}, maxLoops=${this.maxLoops}, count wordsClicked=${this.wordsClicked.length}`
        );

        const minutesSinceLastUserActive = moment().diff(this.lastTimeUserActive, 'minutes');
        const minutesSinceLastWordClicked = this.lastWordClickedTime
            ? moment().diff(this.lastWordClickedTime, 'minutes')
            : 9999;
        this.logger.debug(`onPlayerEnd() minutesSinceLastUserActive=${minutesSinceLastUserActive}, minutesSinceLastWordClicked=${minutesSinceLastWordClicked}`);
        if (minutesSinceLastUserActive >= 60 && minutesSinceLastWordClicked >= 60) {
            // Unlock player if user hasn't been active since one hour
            this.screenLockComponent.screenLockState = 'unlocked';
        }

        // Only do this if we a are not in a loop
        if (this.maxLoops === null || this.maxLoops <= 1) {
            // if (this.videoPlayerTaskOverlay?.tasks?.length > 0) {
            //     this.videoPlayerTaskOverlay.showResult();
            //     return;
            // }
            if (!this.isAutoPlayNextVideo) {
                // Don't show the play-next overlay if a) in a class, b) has a tag or c) has a subject
                this.screenLockComponent.screenLockState = 'unlocked';
                if (this.shouldShowReviewWordsPrompt) {
                    setTimeout(() => {
                        this.showReviewWordsPrompt();
                    }, 2000);
                }
            } else {
                this.videosApi.getPlayNext(this.videoInfo._id).subscribe(async response => {
                    if (response.success) {
                        this.logger.debug('Got next video:', response.nextVideo);
                        this.translationsApi.getSubtitlesForVideo(response.nextVideo._id, this.language).subscribe(
                            (responseSubtitles) => {
                                this.logger.debug(
                                    `play next getSubtitlesForVideo got ${
                                        responseSubtitles.data && responseSubtitles.data.subtitles
                                            ? responseSubtitles.data.subtitles.length
                                            : 'no'
                                    } text chunks `
                                );
                                this.showNextVideoOverlay(this.shouldShowReviewWordsPrompt, response.nextVideo, responseSubtitles.success).then();
                            }
                        );
                    } else {
                        this.screenLockComponent.screenLockState = 'unlocked';
                        if (this.shouldShowReviewWordsPrompt) {
                            setTimeout(() => {
                                this.showReviewWordsPrompt();
                            }, 2000);
                        }
                    }
                });
            }
        }
    }

    onPlayerVolumeChange() {
        // Save volume and muted status to preferences (locally only)
        const volume: number = this.videoJSplayer.volume();
        const muted: boolean = this.videoJSplayer.muted();
        this.appData.savePreferenceString(this.constants.pref.PLAYER_VOLUME, volume.toString());
        this.appData.savePreferenceString(this.constants.pref.PLAYER_MUTED, muted ? '1' : '0');
    }

    get shouldShowReviewWordsPrompt(): boolean {
        let show = false;
        // Do not show review words prompt for educators or if it's a loop
        if (
            (!this.appData.authenticatedUser ||
                this.appData.authenticatedUser.role !== 'educator') &&
            (this.maxLoops === null || this.maxLoops <= 1) &&
            this.wordsClicked.length > 0
        ) {
            show = true;
        }
        this.logger.debug('showReviewWordsPrompt: ', show);
        return show;
    }

    get isAutoPlayNextVideo() {
        return !this.class_id && !this.tag && !this.subjectSlug && (this.maxLoops === null || this.maxLoops <= 1);
    }

    get isIOsWeb() {
        return this.plt.is('ios') && !this.plt.is('hybrid'); // hybrid: a device running Capacitor or Cordova
    }

    onScreenOrientationChange() {
        // See https://github.com/apache/cordova-plugin-screen-orientation
        this.logger.debug('Orientation changed', this.screenOrientation.type);
        if (this.screenOrientation.type.startsWith('landscape')) {
            this.appComponent.setStatusBarStyleDark();
            this.appComponent.hideStatusBar();
        } else {
            this.appComponent.setStatusBarStyleLight();
            // Show status bar again (only if video is not in full screen)
            if (!this.isVideoFullscreen) {
                this.appComponent.showStatusBar();
            }
        }
    }

    /**
     * Clear popovers
     */
    destroyAllPopovers() {
        // Not destroying the popovers - see https://gitlab.com/uugotit/webapp/issues/20

        // create a copy of the array (because popovers will be removed from the array in close()):
        const popovers = [].concat(this.popovers);
        this.logger.debug('destroyAllPopovers', popovers.length);
        popovers.forEach((popover, i) => {
            this.logger.debug('Destroying popover ', i, popover.popper);
            if (popover.closeTimeout) {
                clearTimeout(popover.closeTimeout);
                popover.closeTimeout = null;
            }
            popover.popper.close();

            // https://github.com/FezVrasta/popper.js/issues/107
            // this.popovers[i].drop.modifiers.offset = {
            //   enabled: true,
            //   offset: 40
            // };
        });

        // this.popovers = [];
    }

    /**
     * Updates the subtitles on the screen
     *
     * @param index Index of the subtitle in the array
     * @param chunk The subtitles to be displayed
     */
    updateSubtitle(index: number, chunk: ITranslationSubtitles) {
        const colorMapping = {
            'START_TEXT_WHITE_TAG': 'text-white',
            'START_TEXT_YELLOW_TAG': 'text-yellow',
            'START_TEXT_CYAN_TAG': 'text-cyan',
            'START_TEXT_GREEN_TAG': 'text-green',
        };
        this.logger.debug('updateSubtitle', index);
        // this.destroyAllPopovers();
        this.currentChunkIndex = index;
        let subtitleHtml = '&nbsp;';
        // let splitOriginal = chunk.text.split(" ");
        if (chunk.oriTok) {
            let hasToCloseColor = false;
            const colorClass = chunk.oriTok[1];
            if (colorClass && colorMapping[colorClass.text]) {
                subtitleHtml += `<span class="${colorMapping[colorClass.text]}">`;
                hasToCloseColor = true;
            }
            for (let j = 0; j < chunk.oriTok.length; j++) {
                const oriToken = chunk.oriTok[j];
                if (!['PUNCT', 'SPACE'].includes(oriToken.pos) && !['<', '>', '&', '*', '[', ']', 'END_COLOR_TAG'].includes(oriToken.text)) {
                    // const ne = oriToken.ne ? ` (${oriToken.ne})` : '';
                    const textClass = colorMapping[oriToken.text];

                    if (textClass) {
                        subtitleHtml = String(subtitleHtml);
                    } else {
                        subtitleHtml += `<span class="drop-target drop-target-${j}"><span class="drop-target-inner ${textClass}">${oriToken.text}</span></span>`;
                    }
                } else if (['[', ']', 'END_COLOR_TAG'].includes(oriToken.text)) {
                    subtitleHtml = String(subtitleHtml);
                } else {
                    subtitleHtml += oriToken.text;
                }
                if (oriToken.whitespace) {
                    subtitleHtml += ' ';
                }

                setTimeout(() => {
                    // let dropInstance = this.createDropInstance(chunk, j);
                    // this.popovers.push({ drop: dropInstance} );
                    this.initPopperClickTarget(chunk, j, index);
                }, 200);
            }
            if (hasToCloseColor) {
                subtitleHtml += '</span>';
            }
        } else {
            // Legacy code (no NLP)
            for (let j = 0; j < chunk.oriWords.length; j++) {
                if (j > 0) {
                    subtitleHtml += ' ';
                }
                const oriWord = chunk.oriWords[j];
                const wordAndPunctuation = Utils.extractPunctuationAtEndOfWord(oriWord);
                subtitleHtml += `<span class="drop-target drop-target-${j}"><span class="drop-target-inner">${wordAndPunctuation[0]}</span></span>${wordAndPunctuation[1]}`;
                setTimeout(() => {
                    // let dropInstance = this.createDropInstance(chunk, j);
                    // this.popovers.push({ drop: dropInstance} );
                    this.initPopperClickTarget(chunk, j, index);
                }, 200);
            }
        }
        subtitleHtml += '&nbsp;';
        if (this.introAnimation && this.introAnimation.isShown) {
            this.introAnimation.currentSubtitle = subtitleHtml;
        } else {
            this.currentSubtitle = subtitleHtml;
        }
    }

    /**
     * @deprecated We're using initPopperClickTarget instead now
     * Creates a new Drop (popover) instance for a given text chunk and the index within the words of the chunk.
     */
    createDropInstance(chunk: ITranslationSubtitles, wordIndex: number) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const element = document.querySelector(`.drop-target-${wordIndex}`) as HTMLElement;
        const drop = new Drop({
            target: element,
            content: chunk.toWords[wordIndex],
            classes: 'drop-theme-arrows-bounce-dark',
            position: 'top center',
            openOn: undefined,
            // tetherOptions: { }
        });
        drop.on('open', () => {
            this.logger.debug('Drop open event');
            element.classList.add('drop-target-selected');
            const timeout = setTimeout(() => {
                drop.close();
            }, 2500);
            drop.content.onclick = function() {
                this.logger.debug('closing drop...');
                clearTimeout(timeout);
                drop.close();
            };
            // var dropElements = document.getElementsByClassName("drop");
            // let moveToElement = <HTMLElement>document.getElementById('video-subtitle');
            // for (let i = 0; i < dropElements.length; i++) {
            //   moveToElement.appendChild(dropElements[i]);
            // }
        });
        drop.on('close', () => {
            this.logger.debug('Drop close event');
            element.classList.remove('drop-target-selected');
        });
        drop.on('click', () => {
            this.logger.debug('Drop click event');
        });
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisPage = this;
        element.onclick = function(event) {
            if (drop.isOpened()) {
                thisPage.logger.debug(`Closing drop instance${event}`);
                drop.close();
                // event.stopPropagation();
                return false;
            } else {
                thisPage.logger.debug(`Opening drop instance${event}`);
                drop.open();
                // event.stopPropagation();
                return false;
            }
        };

        return drop;
    }

    /**
     * Set up the click listener for a word, will open the Popper popover.
     */
    initPopperClickTarget(
        chunk: ITranslationSubtitles,
        oriWord_index: number,
        subtitlesIndex: number
    ) {
        const selector = `.drop-target-${oriWord_index}`;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const element = document.querySelector(selector) as HTMLElement;
        if (!element) {
            this.logger.debug(
                `initPopperClickTarget: element for selector ${selector} not found`
            );
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisPage = this;
        const originalWord = element.innerText;

        // fromWord_index and fromWord_index are the same
        let fromWord_index = oriWord_index; // Fallback if oriWords_mappings is not defined

        // Get additional words that are connected to this word (for example "das Haus" -> these two words might be translated togehter in Croation)
        const connectedElements: Array<Element> = [];
        const allOriWords = []; // All words from oriWords that have the same index in oriWords_mappings
        if (chunk.oriWords_mappings && chunk.oriWords_mappings.length > 0) {
            fromWord_index = chunk.oriWords_mappings[oriWord_index]; // The index of the word to show from toWords
            for (let i = 0; i < chunk.oriWords_mappings.length; i++) {
                // It's not the wordIndex on which the user clicked on AND it has the same toWords index
                if (i !== oriWord_index && chunk.oriWords_mappings[i] === fromWord_index) {
                    // Get HTML element
                    const connectedElement = document.querySelector(
                        `.drop-target-${i}`
                    );
                    connectedElements.push(connectedElement);
                }
                if (chunk.oriWords_mappings[i] === fromWord_index) {
                    if (chunk.oriWords) {
                        allOriWords.push(chunk.oriWords[i]);
                    } else {
                        allOriWords.push(chunk.oriTok[i]);
                    }

                }
            }
        }

        // Check if we have connected words (based on "link" property)
        if (chunk.fromTok && chunk.fromTok[oriWord_index].link) {
            // const otherIndexes = chunk.fromTok[oriWord_index].link.idx.filter(idx => idx !== oriWord_index);
            chunk.fromTok[oriWord_index].link.indexes.forEach(index => {
                const i = chunk.fromTok.findIndex(tok => tok.index === index);
                this.logger.debug(`index for ${index} is ${i}`);
                if (i != -1 && i != oriWord_index) {
                    const connectedElement = document.querySelector(
                        `.drop-target-${i}`
                    );
                    connectedElements.push(connectedElement);
                    if (chunk.oriWords) {
                        allOriWords.push(chunk.oriWords[i]);
                    } else {
                        allOriWords.push(chunk.oriTok[i]);
                    }
                }
            });
        }

        const fromWord = chunk.fromWords ? chunk.fromWords[fromWord_index] : chunk.fromTok[fromWord_index];
        const toWord = chunk.toWords[fromWord_index];
        // let toWordShow = toWord;// The word that is shown in the popover;
        let toWords = toWord.split('/');
        toWords = toWords.map(word => word.trim()); // remove leading and trailing spaces
        toWords = toWords.filter(word => !word.startsWith('__START_NO_SHOW__') && !word.endsWith('__END_NO_SHOW__'));
        let toWordShow = toWords.join(' / ');
        let isNoTranslation = null;

        // if (fromWord['trText']) {
        //     originalWord = fromWord['trText'];
        // }

        if (chunk.fromTok && chunk.fromTok[fromWord_index]?.ne) {
            const ne = chunk.fromTok[fromWord_index].ne;
            // See https://spacy.io/api/annotation#named-entities
            if (ne && this.language !== 'de') {
                switch (ne) {
                    // There is an issue with ORG: https://gitlab.com/uugotitTeam/product/uu-python-nlp/-/issues/12#note_471604145
                    // case 'ORG':
                    //     toWordShow = thisPage.translate.instant('name_org');
                    //     isNoTranslation = true;
                    //     break;
                    case 'PER':
                    case 'PERSON':
                        toWordShow = thisPage.translate.instant('name_per');
                        isNoTranslation = true;
                        break;
                    // case 'GPE':
                    //     toWordShow = 'Geopolitische Einheit';
                    //     break;
                    // case 'LOC':
                    //     toWordShow = 'Ort';
                    //     break;
                    default:
                        break;
                }
            }
        }

        if (toWordShow === '') {
            toWordShow = thisPage.translate.instant('no_translation');
            isNoTranslation = true;
        }

        const onClickFunction = function(event) {
            thisPage.logger.debug('onclick event of word');

            if (thisPage.introAnimation && thisPage.introAnimation.isShown) {
                if (event) {
                    // event exists only if clicked by user (not automatically)
                    thisPage.logger.debug('Intro animation is not shown and user clicked, unsubscribe');
                    thisPage.introAnimation.animationLoopSubscription.unsubscribe();
                }
            }

            // Only track word_clicked in Google Analytics if it's not on the intro screen:
            // if (!thisPage.introAnimation || !thisPage.introAnimation.isShown) {
            //     thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'word_clicked', originalWord);
            // }

            // Track word_clicked in Google Analytics also if it's on the intro screen:
            thisPage.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'word_clicked', originalWord);

            // The following two lines won't prevent the event to be propagated to the player:
            if (event) {
                event.stopImmediatePropagation();
                event.preventDefault();
            }

            thisPage.lastWordClickedTime = new Date().getTime();

            const popoverExists = thisPage.popovers.find(p => p.wordIndex === oriWord_index && p.subtitlesIndex === subtitlesIndex);
            if (popoverExists) {
                thisPage.logger.debug('Popover EXISTS', popoverExists);
                popoverExists.popper.close();
            } else {
                // Avoid app crash from error in translation
                if (toWordShow) {
                    // This is the div that will be shown as the popup:
                    const popperDiv = document.createElement('div');
                    popperDiv.classList.add('popper');
                    popperDiv.classList.add(`video-subtitle-fontsize-multiplier-${thisPage.subtitleFontSize}`);
                    const popperInnerDiv = document.createElement('div');
                    popperInnerDiv.classList.add('popper-inner');
                    popperDiv.appendChild(popperInnerDiv);
                    const innerHTML = Utils.extractPunctuationAtEndOfWord(toWordShow)[0];
                    popperInnerDiv.innerHTML = innerHTML;
                    if (isNoTranslation) {
                        popperInnerDiv.innerHTML = `<span class="popper-named-entity">${innerHTML}</span>`;
                    }
                    // popperDiv.innerHTML = translatedWord;
                    document.getElementById(thisPage.introAnimation && thisPage.introAnimation.isShown ? 'video-container' : 'main_video').appendChild(popperDiv);

                    const popoverObject: IPopover = {
                        wordIndex: oriWord_index,
                        subtitlesIndex,
                        popper: undefined,
                    };

                    let showForMs = 2500;
                    const clickDelay = thisPage.videoClickDelay;
                    if (clickDelay > 0 && clickDelay < 10 && showForMs < clickDelay * 1000) {
                        showForMs = clickDelay * 1000;
                    }

                    // Create the Popper instance:
                    const popperInstance = new Popper(element, popperDiv, {
                        placement: 'top',
                        removeOnDestroy: true,
                        // offset: 0,
                        // ### This works for popper.js 1.14.4:
                        onCreate(data) {
                            // thisPage.logger.debug('Popper was created', data);
                            element.classList.add('drop-target-selected');
                            connectedElements.forEach(e => e.classList.add('drop-target-selected'));
                            // thisPage.logger.debug('POPPER CREATE', data.instance);
                            popoverObject.closeTimeout = setTimeout(() => {
                                popoverObject.closeTimeout = null;
                                data.instance.close();
                            }, showForMs);
                        },
                    });

                    popoverObject.popper = popperInstance;

                    thisPage.popovers.push(popoverObject);

                    if ((!thisPage.introAnimation || !thisPage.introAnimation.isShown) && !isNoTranslation) {
                        thisPage.checkWordCatalogBeforeSave();

                        /*
                        let fromWordToSave = fromWord;
                        // This is just a temporary workaround to remove der/die/das/etc. from the saved word - only HR and RU translations
                        // See https://gitlab.com/uugotitTeam/uugotit-webapp2/issues/69
                        if (thisPage.translation && thisPage.translation.options.from === 'de'
                            && (thisPage.translation.options.to === 'hr' || thisPage.translation.options.to === 'ru')) {
                            // thisPage.logger.debug('allOriWords', allOriWords);
                            const reg = new RegExp('^der$|^die$|^das$|^dem$|^den$|^denen$|^ein$|^nem$|^eines$|^einem$|^einen$|^eine$|^einer$|^des$|^dessen$|^deren$|^ne$|^ner$|^nem$|^a$|^ane$', 'i');
                            fromWordToSave = allOriWords.filter(word => !reg.test(word)).join(' ');
                            // thisPage.logger.debug('fromWordsJoined', fromWordToSave);
                        }
                        */

                        thisPage.saveWordToCatalog(
                            originalWord,
                            typeof fromWord === 'string' ? fromWord : fromWord.trText || fromWord.text,
                            chunk.oriTok ? chunk.oriTok[fromWord_index] : null, // oriToken
                            chunk.fromTok ? chunk.fromTok[fromWord_index] : null, // fromToken
                            toWord,
                            chunk,
                            subtitlesIndex,
                            oriWord_index,
                            fromWord_index
                        );
                    }

                    // Add a close() method to the popper instance
                    popperInstance.close = function() {
                        thisPage.logger.debug('popperInstance.close');
                        element.classList.remove('drop-target-selected');
                        connectedElements.forEach(e => e.classList.remove('drop-target-selected'));
                        for (let j = 0; j < thisPage.popovers.length; j++) {
                            if (thisPage.popovers[j].wordIndex === oriWord_index && thisPage.popovers[j].subtitlesIndex === subtitlesIndex) {
                                thisPage.popovers.splice(j, 1);
                                break;
                            }
                        }
                        try {
                            popperInstance.destroy();
                        } catch (e) {
                            console.error('Error destroying Popper instance', e);
                        }
                    };

                    popperDiv.onclick = function() {
                        thisPage.logger.debug('Closing popper instance...');
                        clearTimeout(popoverObject.closeTimeout);
                        popoverObject.closeTimeout = null;
                        popperInstance.close();
                    };

                    // ### This worked for popper.js 0.6.4:
                    // popperInstance.onCreate(() => {
                    //   thisPage.logger.debug('Popper was created');
                    //   element.classList.add('drop-target-selected');
                    //   popperInstance.timeout = setTimeout(() => {
                    //       popperInstance.close();
                    //   },
                    //   2500);
                    // });

                    thisPage.onWordClicked(chunk, oriWord_index, subtitlesIndex);
                }
            }
        };

        // element.onclick = onclick;

        element.onmousedown = function(event) {
            thisPage.logger.debug('onmousedown drop-target');
            thisPage.wordClicked = true;
            thisPage.pressTimer = window.setTimeout(function() {
                thisPage.wordClicked = false;
                thisPage.videoJSplayer.pause();
                onClickFunction(null);
            }, 400);
        };

        element.onmouseup = function(event) {
            thisPage.logger.debug('onmouseup drop-target');
            if (thisPage.wordClicked) {
                onClickFunction(null);
            }
            thisPage.wordClicked = false;
            window.clearTimeout(thisPage.pressTimer);
        };
    }

    /**
     * Skips some seconds in the video
     *
     * @param seconds The seconds to be skipped
     * @param source The video source
     * @param playVideo will start playing the video if it's currently paused
     */
    async skipVideo(seconds: number, source: string, playVideo = false) {

        this.logger.debug('Skipping video %s seconds', seconds);
        const currentTime: number = this.videoJSplayer.currentTime();
        this.videoJSplayer.currentTime(currentTime + seconds);
        this.analytics.trackAnalyticsEvent(
            AnalyticsCategory.Video,
            'skip_' + source,
            seconds < 0 ? 'back' : 'forward'
        );

        if (playVideo && this.videoJSplayer.paused()) {
            await this.checkLandscapeFullscreen();
            if (await this.checkShowVideoIntro(true) === false) {
                this.videoJSplayer.play();
            }
        }
        this.videoJSplayer.userActive(true);
    }

    /**
     * Gets video URL of quality which is closest to the quality selected by user
     *
     * @param videoQuality The quality needed
     * @returns The Video URL
     */
    getVideoUrlForQuality(videoQuality: string): IVideoPlaybackUrl {
        // Order of qualities
        const qualities = { low: 0, medium: 1, high: 2, veryHigh: 3 };

        let videoUrl: IVideoPlaybackUrl = null,
            best = 4;

        // Loop video sources and select closest available quality
        this.videoInfo.sources.forEach((source) => {
            const value = Math.abs(qualities[source.resolution] - qualities[videoQuality]);
            if (value < best) {
                best = value;
                videoUrl = source;
            }
        });

        return videoUrl;
    }

    get defaultTranslationOptions(): ITranslationOptions {
        const options: ITranslationOptions = {
            from: 'de',
            to: this.language,
            preprocessText: null,
            preprocessWords: null,
            processWithAlignment: null,
            process: null,
            processText: null,
            postprocessText: null,
            postprocessWords: null,
        };
        return options;
    }

    /**
     * This method is used both in the popup and the page itself
     */
    switchPlaybackRateCallback = async (step: number) => {

        await this.checkLandscapeFullscreen();
        await this.checkShowVideoIntro(false);

        this.playbackRateIndex += step;
        if (this.playbackRateIndex > this.playbackRates.length - 1) {
            this.playbackRateIndex = 0;
        } else if (this.playbackRateIndex < 0) {
            this.playbackRateIndex = this.playbackRates.length - 1;
        }
        this.playbackRate = this.playbackRates[this.playbackRateIndex];
        this.logger.debug(
            `Changing playback rate to ${this.playbackRate} new index: ${this.playbackRateIndex}`
        );
        this.videoJSplayer.playbackRate(this.playbackRate);

        const prefKey = this.constants.pref.PLAYBACK_RATE_INDEX;
        const value = this.playbackRateIndex.toString();
        this.appData.savePreferenceString(prefKey, value);

        return this.playbackRate;
    };


    get hideSubtitles(): boolean {
        return this.appData.getPreferenceString(this.constants.pref.PLAYER_HIDE_SUBTITLES, '0') === '1';
    }

    set hideSubtitles(hideSubtitles: boolean) {
        const prefKey = this.constants.pref.PLAYER_HIDE_SUBTITLES;
        const value = hideSubtitles ? '1' : '0';
        this.appData.savePreferenceString(prefKey, value);
    }

    get subtitleFontSize(): string {
        return this.appData.getPreferenceString(
            this.constants.pref.SUBTITLE_FONT_SIZE,
            this.constants.DefaultSubtitleFontSize
        );
    }

    get videoClickDelay(): number {
        return Number.parseInt(
            this.appData.getPreferenceString(
                this.constants.pref.VIDEO_CLICK_DELAY,
                this.constants.DefaultVideoClickDelay
            ),
            10
        );
    }

    /**
     * Toggles subtitles on and off.
     */
    toggleSubtitlesOnOff = async () => {
        this.hideSubtitles = !this.hideSubtitles;
        await this.checkLandscapeFullscreen();
        await this.checkShowVideoIntro(false);
    };

    async playVideo() {
        if (!this.canPlayVideo && !this.appData.authenticatedUser) {
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('premium_functionality_title'),
                subHeader: this.translate.instant('premium_functionality_enable_video_in_class'),
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel',
                    },
                    {
                        text: this.translate.instant('btn_subscribe'),
                        handler: () => {
                            if (this.plt.is('cordova')) {
                                window.open('https://uugot.it/sba/', '_system');
                            } else {
                                window.open('https://uugot.it/sba/', '_blank');
                            }
                        },
                    },
                ],
            });
            await alert.present();
            return;
        }
        if (this.canPlayVideo) {
            this.playClicked = true;
            this.updateWatchedVideo(0, true);
            await this.checkLandscapeFullscreen();
            const shouldCheckVideoIntro = window.innerWidth > 300;
            if (shouldCheckVideoIntro) {
                if (await this.checkShowVideoIntro(true) === false) {
                    this.videoJSplayer.play();
                }
            } else {
                this.videoJSplayer.play();
            }
        } else {
            const role = this.appData.authenticatedUser?.role;
            const subHeaderKey = role === 'educator' ? 'limit_videos_educator' : 'limit_videos_reached_student';
            const buttons = [
                {
                    text: this.translate.instant('video_library'),
                    handler: () => {
                        this.navCtrl.navigateRoot('/catalog/all');
                    },
                },
            ];
            if (role === 'educator') {
                buttons.push({
                    text: this.translate.instant('btn_subscribe'),
                    handler: () => {
                        this.navCtrl.navigateRoot('login');
                    },
                });
            }
            const alert = await this.alertCtrl.create({
                header: 'Oops :(',
                message: this.translate.instant(subHeaderKey),
                buttons,
            });
            await alert.present();
        }

    }

    pauseVideo() {
        this.videoJSplayer.pause();
    }

    /**
     * Checks whether the video intro overlay should be shown or not upon user interaction.
     * @param playOnClose if this is true, then the video will start playing once the intro tutorial is closed by the user
     * @returns true if the intro overlay is shown
     */
    private async checkShowVideoIntro(playOnClose = false): Promise<boolean> {
        const hideIntro =
            this.appData.getPreferenceString(this.constants.pref.HIDE_VIDEO_INTRO_OVERLAY) === '1';
        if (
            !this.appData.videoPlayerIntroShown &&
            !hideIntro &&
            (_.isNil(this.currentTime) || this.currentTime === 0)
        ) {
            await this.videoOverlayIntro2.show({ playOnClose });
            return true;
        }
        return false;
    }

    /**
     * Get the preferred lanscape orientation mode.
     * Because of the "notch" on iOS we need to lock to landscape-primary because otherwise
     * it would cover the "lock screen" button.
     */
    private get preferredLandscapeOrientation(): OrientationLockType {
        return (
            this.plt.is('ios')
                ? this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY
                : this.screenOrientation.ORIENTATIONS.LANDSCAPE
        ) as OrientationLockType;
    }

    /**
     * Checks whether the app needs to be rotated to landscape and put in full screen mode.
     */
    private async checkLandscapeFullscreen(): Promise<void> {
        const isDev = true;
        if (isDev) {
            // return;
        }
        if (this.isEmbedded) {
            return;
        }
        if (!this.isIntitialFullscreenChange) {
            this.isIntitialFullscreenChange = true;

            if (this.plt.is('mobile') && !this.plt.is('ipad') && !this.plt.is('tablet')) {

                // This needs to be done first ("The page needs to be fullscreen in order to call screen.orientation.lock().")
                // Full screen does not work on iOS
                if (this.videoJSplayer?.supportsFullScreen() && !this.plt.is('ios')) {
                    this.logger.debug(
                        'checkLandscapeFullscreen() video.js player supports full screen - requesting'
                    );
                    this.videoJSplayer.requestFullscreen();
                } else {
                    this.logger.debug(
                        'checkLandscapeFullscreen() video.js player doesn\'t support full screen'
                    );
                }

                // ### Lock to landscape mode
                this.logger.debug('checkLandscapeFullscreen() requesting landscape mode');
                await this.lockToLandscapeOrientation();
            }
        }
    }

    /**
     * Lock device to landscape mode
     */
    private async lockToLandscapeOrientation(): Promise<boolean> {
        let success = false;
        const orientation = this.preferredLandscapeOrientation;
        try {
            await this.screenOrientation.lock(orientation);
            success = true;
            this.logger.debug('Sucessfully locked to landscape mode (native plugin)');
        } catch (failure) {
            this.logger.debug(
                'Device could not be locked to landscape mode (native plugin)',
                failure
            );
            try {
                await screen.orientation.lock(orientation);
                success = true;
                this.logger.debug('Sucessfully locked to landscape mode (web)');
            } catch (failureWeb) {
                this.logger.debug('Device could not be locked to landscape mode (web)', failureWeb);
            }
        }
        return success;
    }

    /**
     * Display popover
     *
     * @param ev Event
     */
    async presentPopover(ev) {
        this.logger.debug('presentPopover playbackRate %s', this.playbackRate);

        const popover = await this.popoverCtrl.create({
            component: VideoPlayerPopoverPage,
            event: ev,
            // playbackRate: this.playbackRate,
            // changePlaybackRateCallback: (newPlaybackRate: number) => {
            //   this.logger.debug('Changing playback rate to %s', newPlaybackRate);
            //   this.playbackRate = newPlaybackRate;
            //   this.videoJSplayer.playbackRate(newPlaybackRate);
            // },
            // switchPlaybackRateCallback: this.switchPlaybackRateCallback
        });

        await popover.present();
    }

    async presentEmbedModal(videoLink: string) {
        const modal = await this.modalController.create({
            component: EmbedShareModalComponent, // Create a modal component to display the embed code
            componentProps: {
                videoLink,
            },
            cssClass: 'videoEmbedCode'
        });
        return await modal.present();
    }

    /**
     * Opens a social share dialog to share the URL of the video.
     */
    async openShareDialog(analyticsValue?: string) {

        await this.checkLandscapeFullscreen();
        await this.checkShowVideoIntro(false);
        if (this.videoJSplayer.isFullscreen()) {
            this.videoJSplayer.exitFullscreen();
        }

        if (analyticsValue) {
            this.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'video_share_open_dialog', analyticsValue);
        }

        // Check if sharing via email is supported
        // this.socialSharing
        //     .canShareViaEmail()
        //     .then(() => {
        //         // Sharing via email is possible
        //         this.logger.debug('Sharing via email is possible.');
        //     })
        //     .catch(() => {
        //         // Sharing via email is not possible
        //         this.logger.debug('Sharing via email is not possible.');
        //     });

        const options = this.shareOptions;

        const navi = window.navigator as any;

        if (this.plt.is('cordova')) {
            const clonedOpts = {
                title: options.subject,
                text: options.message,
                dialogTitle: options.chooserTitle
                // Remove URL, because with the URL it will be added again at the bottom when sharing on Twitter for example
            };
            this.logger.debug('Share options:', clonedOpts);
            try {
                const shareResult = await Share.share(clonedOpts);
                this.logger.debug(`Shared with acticity type: ${shareResult.activityType}`);
            } catch (err) {
                this.logger.debug(`Sharing failed with error: ${err}`);
            }

        // } else if (navi && navi.share) {
        //     this.logger.debug('Sharing with window.navigator.share');
        //     navi.share({
        //         title: options.subject,
        //         text: options.message,
        //         url: options.url,
        //     })
        //         .then(function() {
        //             this.logger.debug('Successful share');
        //         })
        //         .catch(function(error) {
        //             this.logger.error('Error sharing:', error);
        //         });
        } else {
            this.logger.debug(
                'Platform is not cordova, and window.navigator.share is not available'
            );

            // const mail = document.createElement('a');
            // mail.href = this.sharerLinks.mailto;
            // mail.click();
            const buttons = [
                {
                    text: this.translate.instant('email'),
                    icon: 'mail',
                    handler: () => {
                        this.logger.debug('Delete clicked');
                        this.analytics.trackAnalyticsEvent(
                            AnalyticsCategory.Video,
                            'video_share_open_mail',
                            analyticsValue
                        );
                        const mail = document.createElement('a');
                        mail.href = this.sharerLinks.mailto;
                        mail.click();
                    },
                },
                {
                    text: 'Facebook',
                    icon: 'logo-facebook',
                    handler: () => {
                        this.logger.debug('Facebook clicked');
                        this.analytics.trackAnalyticsEvent(
                            AnalyticsCategory.Video,
                            'video_share_open_facebook',
                            analyticsValue
                        );
                        const a = document.createElement('a');
                        a.href = this.sharerLinks.facebook;
                        a.target = '_blank';
                        a.click();
                    },
                },
                {
                    text: 'Twitter',
                    icon: 'logo-twitter',
                    handler: () => {
                        this.logger.debug('Twitter clicked');
                        this.analytics.trackAnalyticsEvent(
                            AnalyticsCategory.Video,
                            'video_share_open_twitter',
                            analyticsValue
                        );
                        const a = document.createElement('a');
                        a.href = this.sharerLinks.twitter;
                        a.target = '_blank';
                        a.click();
                    },
                },
            ];
            if (this.isEmbedded) {
                buttons.push({
                    text: this.translate.instant('btn_embed_video'),
                    icon: 'code',
                    handler: () => {
                        let embedUrl = `${this.appManager.appBaseUrl}/embed/${this.videoInfo._id}`;
                        if (this.clip && this.clip._id && this.class_id) {
                            const clipId = this.clip._id;
                            const groupId = this.class_id;
                            embedUrl = `${this.appManager.appBaseUrl}/embed/${this.videoInfo._id}/${groupId}/clip/${clipId}`;
                        }

                        this.presentEmbedModal(embedUrl);
                        this.analytics.trackAnalyticsEvent(
                            AnalyticsCategory.Video,
                            'video_embed_clicked',
                            analyticsValue
                        );
                    },
                });
            }
            buttons.push({
                text: this.translate.instant('btn_cancel'),
                icon: 'btn_cancel',
                //@ts-ignore
                role: 'cancel',
                handler: () => {
                    this.logger.debug('Cancel clicked');
                    this.analytics.trackAnalyticsEvent(
                        AnalyticsCategory.Video,
                        'video_share_cancel',
                        analyticsValue
                    );
                },
            },);
            const actionSheet = await this.actionSheetController.create({
                header: this.translate.instant('btn_share_video'),
                buttons
            });
            await actionSheet.present();
        }

    }

    get shareOptions(): {
        message?: string;
        subject?: string;
        files?: string | string[];
        url?: string;
        chooserTitle?: string;
    } {
        // const url = environment.appBaseUrl + this.router.url;
        const url = `${this.appManager.appBaseUrl}/video/${this.videoInfo.websource_id}/${this.videoInfo._id}`;
        const options = {
            message: this.translate.instant('share_message_app', {
                title: this.videoInfo.title,
                url,
            }), // not supported on some apps (Facebook, Instagram)
            subject: this.translate.instant('share_subject_app', { title: this.videoInfo.title }), // fi. for email
            url,
            chooserTitle: this.translate.instant('share_choose_app'), // Android only, you can override the default share sheet title
            // files: ['', ''], // an array of filenames either locally or remotely
            // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
            // iPadCoordinates: '0,0,0,0' // iOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
        };
        return options;
    }

    get sharerLinks(): { mailto: string; facebook: string; twitter: string; } {
        const options = this.shareOptions;
        const tweetText = this.translate.instant('share_tweet_text', {
            title: this.videoInfo.title,
        });
        const mailMessage = this.translate.instant('share_message_web', {
            title: this.videoInfo.title,
            url: options.url,
        });

        // For Twitter, see https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
        return {
            mailto: `mailto:?subject=${encodeURIComponent(
                options.subject
            )}&body=${encodeURIComponent(mailMessage)}`,
            twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                options.url
            )}&text=${encodeURIComponent(tweetText)}`, // &via=uugot_it
            facebook: `https://www.facebook.com/sharer.php?u=${encodeURIComponent(options.url)}`,
        };
    }

    /**
     * HTML code for something like "Video über E-Mail, Twitter oder Facebook teilen." including links.
     */
    getShareTextHtml(): Observable<string> {
        const sharerLinks = this.sharerLinks;
        return this.translate.get('share_text_web', {
            mailto: sharerLinks.mailto,
            twitter: sharerLinks.twitter,
            facebook: sharerLinks.facebook,
        });
    }

    /**
     * Opens a social share dialog to share the URL of the video.
     */
    async openEmbedDialog(ev: Event) {
        const popover = await this.popoverCtrl.create({
            component: VideoPlayerEmbedPopoverPage,
            event: ev,
            translucent: false,
            componentProps: {
                embedUrl: `${this.appManager.appBaseUrl}/embed/${this.videoInfo._id}`
            },
            cssClass: 'embed-dialog'
        });

        await popover.present();
    }

    // /**
    //  * Get the download link for the video transcript (for educators).
    //  */
    // getTranscriptDownloadUrl() {
    //     if (this.videoInfo) {
    //         return `${environment.serviceApiBaseUrl}/subtitles/transcript/${this.videoInfo._id}`;
    //     } else {
    //         return null;
    //     }
    // }

    downloadTranscript() {
        if (this.plt.is('cordova') || this.plt.is('capacitor')) {
            this.translate.get('download_transcript_only_browser').subscribe(i18n => {
                this.uiUtils.showInfoAlert(null, i18n);
            });
        } else {
            this.subtitlesApi.getTranscript(this.videoInfo._id, 'docx').subscribe(data => {
                this.logger.debug('docx data', data);
                const filename = `Transcript_${Utils.prepareForFilename(
                    this.videoInfo?.title || 'video'
                )}_${this.videoInfo?._id}.docx`;
                // See https://esstudio.site/2019/02/16/downloading-saving-and-opening-files-with-cordova.html
                // This could be interesting? https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/downloads/download
                this.downloadFile(data.arrayBuffer, data.contentType, filename);
            }, error => {
                this.logger.info('Error loading transcript: ', error);
                this.uiUtils.showErrorAlert(`${error.status} ${error.statusText}`, null);
            });
        }
    }

    /**
     * Method is use to download a file.
     * @param data - Array Buffer data
     * @param type - type of the document (for eample 'application/vnd.openxmlformats-officedocument.documentml.document').
     */
    downloadFile(data: ArrayBuffer, type: string, filename?: string) {
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        // const pwa = window.open(url);
        // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //     alert('Please disable your Pop-up blocker and try again.');
        // }
        const a = document.createElement('a');
        if (filename) {
            a.download = filename;
        }
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     * Updates the HTML title and meta tags.
     */
    updateHtmlMeta() {
        const options = this.shareOptions;
        this.titleService.setTitle(this.videoInfo.title + ' | uugot.it');
        this.metaService.updateTag({ name: 'description', content: options.subject });
        this.metaService.updateTag({
            property: 'og:title',
            content: this.videoInfo.title + ' | uugot.it',
        });
        this.metaService.updateTag({ property: 'og:description', content: options.subject });
        this.metaService.updateTag({ property: 'og:image', content: this.videoInfo.imageURL });
        this.metaService.updateTag({ property: 'og:type', content: 'video.episode' });
        this.metaService.updateTag({ property: 'og:url', content: options.url });
    }

    checkWordCatalogBeforeSave() {
        // Check if video was accessed from a student class
        if (!this.class_id && !this.appData.isLoggedIn()) {
            // If we are in mobile landscape mode, show toast on top, not bottom, to avoid covering the subtitle
            const toastPosition = this.isMobileLandscape() ? 'top' : 'bottom';
            this.logger.debug('checkWordCatalogBeforeSave()', toastPosition);
            // Make toast a bit shorter than normal
            const toastDuration = 2000;
            const showLimitMessage = () => {
                if (this.wordCatalogLimitMessageCounter % 20 === 0) {
                    this.uiUtils.displayToast(
                        this.translate.instant('words_catalog_limit'),
                        toastPosition,
                        toastDuration
                    );
                }
                ++this.wordCatalogLimitMessageCounter;
            };

            if (this.constants.SaveWordCatalogLocally) {
                const wordsCatalog = this.appData.getWordCatalog();
                if (wordsCatalog.length >= 20) {
                    // Remove oldest word
                    wordsCatalog.pop();
                    this.appData.saveWordCatalog(wordsCatalog);
                    showLimitMessage();
                }
            } else {
                this.wordCatalogApi
                    .getCatalog4Public(this.appData.getInstallationId(), 20, 0, '-created_at')
                    .subscribe((response) => {
                        const wordsCatalog = response.data;
                        if (wordsCatalog.length >= 20) {
                            // Remove oldest word
                            const oldestWord = wordsCatalog[wordsCatalog.length - 1];
                            this.wordCatalogApi
                                .deleteWordsById([oldestWord._id])
                                .subscribe((deleteResponse) => {
                                    this.logger.debug('Deleted word from catalog:', this.wordCatalogLimitMessageCounter, deleteResponse);
                                    showLimitMessage();
                                });
                        }
                    });
            }
        }
    }

    /**
     * Saves a word to the word catalog.
     *
     * @param oriWord Original word
     * @param fromWord Word that was the input for translation
     * @param oriToken NLP token (original)
     * @param fromToken NLP token (base for translation)
     * @param toWord Translation of original word
     * @param chunk The subtitle in which the word is found
     * @param subtitlesIndex The index in the subtitles array in translation doc
     * @param oriWords_index oriWord index in the chunk
     * @param fromWords_index Word index in the chunk
     */
    saveWordToCatalog(
        oriWord: string,
        fromWord: string,
        oriToken: INLPToken,
        fromToken: INLPToken,
        toWord: string,
        chunk: ITranslationSubtitles,
        subtitlesIndex: number,
        oriWords_index: number,
        fromWords_index: number
    ) {
        this.logger.debug(
            `saveWordToCatalog - originalWord: ${oriWord}, fromWord: ${fromWord}, toWord: ${toWord}, chunk: ${JSON.stringify(chunk)}`
        );

        const savedWord: IWordCatalog = {
            translation_id: this.translation._id,
            subtitles_index: subtitlesIndex,
            fromWords_index,
            video_id: this.videoInfo._id,

            oriWord: chunk.oriTok ? oriWord : Utils.removePunctuation(oriWord),
            fromWord: chunk.fromTok ? fromWord : Utils.removePunctuation(fromWord),
            toWord,
            oriToken,
            fromToken,
            oriWords_index,

            from: this.translation.options.from,
            to: this.language,

            start: chunk.start,

            installationID: this.appData.getInstallationId(),
        };

        this.wordsClicked.push(savedWord);

        if (this.class_id && this.appData.authenticatedUser) {
            // Will be saved on the server
            this.wordCatalogApi
                .addWordToClassCatalog(this.class_id, savedWord)
                .subscribe((response) => {
                    this.logger.debug('Add to word catalog response:', response);

                    if (this.appData.isStudent()) {
                        const event: IActivityEvent = {
                            installationID: this.appData.getInstallationId(),
                            group_id: this.class_id,
                            video_id: savedWord.video_id,
                            category: ActivityCategory.word,
                            action: ActivityAction.word_added,
                            label: savedWord.oriWord,
                            metadata: {
                                lang_from: savedWord.from,
                                lang_to: savedWord.to,
                            },
                            generated_at: new Date(),
                        };

                        this.activityService.saveEventToStorage(event);
                    }
                });
        } else if (this.appData.authenticatedUser) {
            // Will be saved on the server
            this.wordCatalogApi.addWordToCatalog(savedWord).subscribe((response) => {
                this.logger.debug('Added word to catalog', savedWord, response);
            });
        } else {
            // Will be saved to localStorage
            if (this.constants.SaveWordCatalogLocally) {
                const savedWordLocal: SavedWord = {
                    translation_id: this.translation._id,
                    subtitles_index: subtitlesIndex,
                    fromWords_index,

                    video_id: this.videoInfo._id,

                    from: this.translation.options.from,
                    to: this.language,

                    oriWord: chunk.oriTok ? oriWord : Utils.removePunctuation(oriWord),
                    fromWord: chunk.fromTok ? fromWord : Utils.removePunctuation(fromWord),
                    toWord: Utils.removePunctuation(toWord),
                    oriText: chunk.oriText,
                    oriWords: chunk.oriWords,
                    oriWords_index,

                    start: chunk.start,

                    created_at: new Date(),

                    websource_id: this.videoInfo.websource_id,

                    imageURL: this.videoInfo.imageURL,
                };
                this.appData.addNewWordToCatalog(savedWordLocal);
            } else {
                this.wordCatalogApi.addWordToCatalog4Public(savedWord).subscribe((response) => {
                    this.logger.debug('Added word to catalog', savedWord, response);
                });
            }
        }
    }

    /**
     * Called when a user swipes on screen
     *
     * @param event Swipe event
     */
    swipeEvent(event: any) {
        this.logger.debug(`SWIPE event ${event}`);
        if (event.deltaX > 20 && Math.abs(event.deltaY) < 10) {
            this.skipVideo(-10, 'swipe');
        } else if (event.deltaX < -20 && Math.abs(event.deltaY) < 10) {
            this.skipVideo(+10, 'swipe');
        }
        // this.videoJSplayer.userActive(true);
    }

    /**
     * Displays review words dialog
     */
    async showReviewWordsPrompt() {
        this.logger.debug('showReviewWordsPrompt');
        const prompt = await this.alertCtrl.create({
            header: this.translate.instant('review_words_header'),
            message: this.translate.instant('review_words_message'),
            buttons: [
                {
                    text: this.translate.instant('not_now'),
                    handler: (data) => {
                        this.logger.debug('Not now clicked');
                        this.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'video_review_words_dialog', 'not_now');
                    },
                },
                {
                    text: this.translate.instant('yes_review'),
                    handler: (data) => {
                        this.logger.debug('Review clicked');
                        this.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'video_review_words_dialog', 'yes_review');
                        if (this.class_id) {
                            this.navCtrl.navigateForward(`word-catalog/${this.class_id}`);
                        } else {
                            this.navCtrl.navigateForward('word-catalog');
                        }
                    },
                },
            ],
        });
        await prompt.present();
    }

    async showNextVideoOverlay(shouldShowReviewWordsPrompt: boolean, nextVideo?: IVideo, hasSubtitles = false) {
        if (nextVideo) {
            // setTimeout(() => {
            //     this.alertCtrl.create({
            //         header: 'Nächstes Video?',
            //         subHeader: 'subHeader',
            //         message: `Möchtest du das nächste Video "${nextVideo.title}" anschauen?`,
            //         buttons: [
            //             {
            //                 text: this.translate.instant('next_video'),
            //                 handler: () => {
            //                     this.logger.debug('User wants to watch next video');
            //                     // const state: any = {
            //                     //     // tag: this.tag,
            //                     //     // subjectSlug: this.subjectSlug
            //                     // };

            //                     // if (this.isAlreadyWatched(videoInfo._id)) {
            //                     //     const watchedVideo = this.appData.getWatchedVideo(this.videoInfo._id);
            //                     //     const duration = Utils.getDurationOfVideoInSeconds(this.videoInfo);
            //                     //     if (
            //                     //         duration &&
            //                     //         watchedVideo.watchedUntil &&
            //                     //         watchedVideo.watchedUntil > this.constants.VideoWatchedThresholdSeconds &&
            //                     //         watchedVideo.watchedUntil < duration - this.constants.VideoWatchedThresholdSeconds
            //                     //     ) {
            //                     //         state.initialTimeInVideo = watchedVideo.watchedUntil;
            //                     //         state.showToastText = this.translate.instant('resuming_video_at', {
            //                     //             time: this.uiUtils.secondsToTimeString(watchedVideo.watchedUntil),
            //                     //         });
            //                     //     }
            //                     // }

            //                     // const navigationExtras: NavigationExtras = {
            //                     //     state
            //                     // };

            //                     // if (this.appComponent.routerOutlet.canGoBack()) {
            //                     //     this.navCtrl.pop().then(() => {
            //                     //         this.navCtrl.navigateForward(`video/${showNextVideo.websource_id}/${showNextVideo._id}`, navigationExtras);
            //                     //     });
            //                     // } else {
            //                     //     const options: NavigationOptions = {
            //                     //         animated: false
            //                     //     };
            //                     //     this.navCtrl.navigateRoot('catalog', options).then(() => {
            //                     //         this.navCtrl.navigateForward(`video/${showNextVideo.websource_id}/${showNextVideo._id}`, navigationExtras);
            //                     //     });
            //                     // }

            //                     this.initNewVideo(nextVideo);

            //                 }
            //             }
            //         ]
            //     }).then(async alert => {
            //         this.logger.debug('Presenting alert', alert);
            //         await alert.present();
            //     });
            // }, 500);

            /*
            const popover = await this.popoverCtrl.create({
                component: VideoPlayerNextVideoPopoverPage,
                event: null,
                translucent: false,
                componentProps: {
                    video: nextVideo,
                    showReviewWordsPrompt: shouldShowReviewWordsPrompt
                },
                cssClass: 'nextVideo-dialog'
            });

            popover.onDidDismiss().then(returnedEvent => {
                if (returnedEvent.data !== undefined) {
                    switch (returnedEvent.data.resultAction) {
                        case 'goToNext':
                            this.initNewVideo(returnedEvent.data.video, true);
                            break;
                        case 'reviewWords':
                            this.logger.debug('Review clicked (popover)');
                            if (this.class_id) {
                                this.navCtrl.navigateForward(`word-catalog/${this.class_id}`);
                            } else {
                                this.navCtrl.navigateForward('word-catalog');
                            }
                            break;
                        default:
                            break;
                    }
                }
            });

            await popover.present();
            */

            this.videoJSplayer.controlBar.hide();
            this.videoPlayNextOverlay.init(
                nextVideo,
                (result) => {
                    this.videoJSplayer.controlBar.show();
                    switch (result.action) {
                        case 'goToNext':
                        case 'goToNextTimeout': {
                            // there is an issue in Safari on iOS with automatically starting the video without user interaction
                            // so we need to show the play button
                            const autoStart: boolean = result.action === 'goToNext' || !this.isIOsWeb;
                            this.initNewVideo(result.nextVideo, autoStart, hasSubtitles);
                            break;
                        }
                        case 'reviewWords': {
                            this.logger.debug('Review clicked (popover), fullscreen?', this.videoJSplayer.isFullscreen());
                            this.screenLockComponent.screenLockState = 'unlocked';
                            if (this.videoJSplayer.isFullscreen()) {
                                this.videoJSplayer.exitFullscreen();
                                this.appComponent.showStatusBar();
                            }
                            if (this.class_id) {
                                this.navCtrl.navigateForward(`word-catalog/${this.class_id}`);
                            } else {
                                this.navCtrl.navigateForward('word-catalog');
                            }
                            break;
                        }
                        case 'replay': {
                            this.replayVideoFromStart();
                            break;
                        }
                        default: {
                            this.screenLockComponent.screenLockState = 'unlocked';
                            break;
                        }
                    }
                    this.analytics.trackAnalyticsEvent(AnalyticsCategory.Video, 'playnext_action', result.action);
                },
                shouldShowReviewWordsPrompt,
                true
            );

        } else if (shouldShowReviewWordsPrompt) {
            setTimeout(() => {
                this.showReviewWordsPrompt();
            }, 2000);
        }
    }

    /**
     * Adds video to group selected by user in the alert
     *
     * @param videoId The video ID of the video to add
     */
    async addVideoToClass(videoId: string) {
        if (!videoId) {
            return;
        }
        this.videoPlayerEducatorOverlay?.addClipToClass();
        // await this.sharedUiService.showAddVideoToClassDialog(videoId);
    }

    /**
     * Returns seconds converted to a Date object (for formatting with Angular's date pipe).
     * @param seconds for example 70.5
     */
    secondsAsDate(seconds: number) {
        return new Date(seconds * 1000);
    }

    /**
     * Don't show expiry date if it's more than 2 years in the future.
     * @param video the video
     * @returns true if the "expiry date" of the video should be shown on the UI.
     */
    showExpiryDate(video: IVideo): boolean {
        if (!video.expiryDate) {
            return false;
        }
        const expiryDate = new Date(video.expiryDate);
        const now = new Date();
        const diffDays = (expiryDate.getTime() - now.getTime()) / 1000 / 3600 / 24;
        // Don't show date if it's more than 2 years in the future
        return diffDays < 365 * 2;
    }

    isMobileLandscape(): boolean {
        // this.logger.debug('mobile %s, landscape %s', this.plt.is('mobile'), this.plt.isLandscape());
        return this.plt.is('mobile') && this.plt.isLandscape();
    }

    public availableTranslationLanguages(video: IVideo): ILanguage[] {
        let languages = this.appData.getSubtitleLanguagesForLoggedInUser();
        languages = languages.filter(lang => lang.code.toLowerCase() !== video.originalLang);
        languages.forEach(lang => this.uiUtils.setDisplayNameOfLanguage(lang));
        if (this.plt.is('mobile')) {
            languages.forEach(lang => lang['displayName'] = lang['displayName'].replace(' (beta)', ''));
        }
        languages = languages.sort((a, b) => a['displayName'].localeCompare(b['displayName']));
        return languages;
    }

    getSelectedLanguage(): ILanguages {
        return this.language;
    }

    async selectLanguage() {
        this.logger.debug(`selectedLanguage: ${this.language}`);

        const languages = this.availableTranslationLanguages(this.videoInfo);
        const inputs = languages.map((lang) => {
            return {
                name: lang.code,
                type: 'radio' as
                    | 'number'
                    | 'search'
                    | 'password'
                    | 'time'
                    | 'text'
                    | 'tel'
                    | 'url'
                    | 'email'
                    | 'date'
                    | 'checkbox'
                    | 'radio',
                // label: `${lang.name_native} ${lang.name}`,
                label: lang['displayName'],
                value: lang.code,
                checked: this.language === lang.code,
            };
        });
        const alert = await this.alertCtrl.create({
            header: 'Translation language',
            inputs,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        this.logger.debug('Confirm Cancel');
                    },
                },
                {
                    text: 'Ok',
                    handler: (newLangCode) => {
                        this.onUserSelectedLanguage(newLangCode);
                    },
                },
            ],
        });

        await alert.present();
    }

    onUserSelectedLanguage(newLangCode: ILanguages) {
        this.logger.debug('OK clicked. Data -> ' + JSON.stringify(newLangCode));
        if (newLangCode !== this.language) {
            const prefKey = this.constants.pref.TRANSLATION_LANG;
            this.appData.savePreferenceString(prefKey, newLangCode);
            this.language = newLangCode;
            this.loadSubtitles(true);
        }
        if (this.introAnimation && this.introAnimation.isShown) {
            this.onCloseSettingsOverlay();
        }
    }

    onMainMenuItemSelected(id: ISettingsMainItem['id']): boolean {
        if (id === 'video_overlay_intro') {
            this.videoOverlayIntro2.show();
            return true;
        }
        return false;
    }

    async showSettingsOverlay(setting?: ISettingsMainItem['id']) {
        const isPaused = this.videoJSplayer.paused();
        if (!isPaused) {
            this.videoJSplayer.pause();
        }
        await this.checkLandscapeFullscreen();
        await this.checkShowVideoIntro(false);
        this.settingsOverlayShown = true;
        this.settingsComponent.onShow(setting, { playOnClose: !isPaused });

        this.logger.debug('showSettingsOverlay'/*, document.querySelector('#video-overlay-settings')*/);
        const animation = this.animationCtrl
            .create()
            .addElement(document.querySelector('#video-overlay-settings'))
            .duration(400)
            // .fromTo('transform', 'translateX(100%)', 'translateX(0%)')
            .fromTo('opacity', '0', '1');
        animation.play();

        this.destroyAllPopovers();
    }

    // ### ISettingsListener & ISettingsChangeListener

    onCloseSettingsOverlay(configuration?: ISettingsConfiguration) {
        const animation = this.animationCtrl.create()
            .addElement(document.querySelector('#video-overlay-settings'))
            .duration(400)
            // .fromTo('transform', 'translateX(100%)', 'translateX(0%)')
            .fromTo('opacity', '1', '0');
        animation.play();
        setTimeout(() => {
            this.settingsOverlayShown = false;
            if (configuration?.playOnClose) {
                this.playVideo();
            }
        }, 400);
    }

    onSettingChanged(id: ISettingsMainItem['id'], value: string) {
        switch (id) {
            case 'language':
                this.onUserSelectedLanguage(value as ILanguages);
                break;
            case 'video_quality':
                this.userSelectedVideoQuality(value as IVideoPlaybackUrl['resolution']);
                break;
            default:
                break;
        }
    }

    /**
     * The user changed the video quality
     * @param videoQuality 'low' | 'medium' | 'high' | 'veryHigh'
     */
    userSelectedVideoQuality(videoQuality: IVideoPlaybackUrl['resolution']) {
        const videoPlaybackUrl = this.getVideoUrlForQuality(videoQuality);
        this.logger.debug('userSelectedVideoQuality', videoPlaybackUrl);
        const currentTime = this.currentTime;
        const isPaused = this.videoJSplayer.paused();
        this.videoJSplayer.src({
            src: videoPlaybackUrl.link,
            type: videoPlaybackUrl.type,
        });
        this.videoJSplayer.poster(null);
        this.videoJSplayer.currentTime(currentTime);
        if (!isPaused) {
            this.videoJSplayer.play();
        }
    }

    // ### IVideoOverlayIntroListener

    onCloseVideoIntroOverlay(configuration?: IVideoOverlayIntroConfiguration) {
        const isLanguageSelectionShown = this.appData.getPreferenceString(this.constants.pref.LANGUAGE_SELECTION_SHOWN) === '1';
        // const translationLang = this.appData.getPreferenceString(this.constants.pref.TRANSLATION_LANG) as ILanguages;
        this.logger.debug(`onCloseVideoIntroOverlay configuration=${JSON.stringify(configuration)}`);
        if (!isLanguageSelectionShown) {
            this.settingsOverlayShown = true;
            this.appData.savePreferenceString(this.constants.pref.LANGUAGE_SELECTION_SHOWN, '1');
            const playOnClose = _.isNil(configuration?.playOnClose) ? true : configuration.playOnClose;
            this.settingsComponent.onShow('language', { playOnClose });
        } else {
            if (configuration?.playOnClose) {
                this.playVideo();
            }
        }
    }

    /**
     * Update the "WatchedVideo" on the server or in local storage.
     * @param newTime time in seconds (or undefined if the user hasn't started watchin a video).
     */
    private updateWatchedVideo(newTime: number, force?: boolean) {
        // this.logger.debug(`WatchedVideo new time is ${newTime}, is scrubbing? ${this.videoJSplayer.scrubbing()}`);

        if (!this.videoInfo) {
            return;
        }

        if (this.videoJSplayer.scrubbing()) {
            // we don't update the time while the user is scrubbing
            return;
        }

        const expiryDate = this.videoInfo.expiryDate
            ? new Date(this.videoInfo.expiryDate)
            : undefined;

        const newTimeRounded = _.round(newTime, 3);

        const dur = this.clip?.duration || this.videoInfo.duration || this.videoJSplayer.duration();
        const fin = dur && newTimeRounded > dur - 6 ? true : undefined; // We consider it finished once the last 6 seconds are reached

        if (
            this.clippingStart > -1 ||
            this.clippingEnd > -1 && this.clippingEnd < this.videoInfo.duration
        ) {
            this.logger.debug(`Updated WatchedVideo: Not updating watched video time ${newTimeRounded} (clipping)`);
        } else if (force && newTimeRounded === 0) {
            this.appData.addOrUpdateWatchedVideo(this.videoInfo._id, newTimeRounded, expiryDate, dur, fin);
            this.logger.debug(`Updated WatchedVideo: With time 0 (force)`);
        } else if (
            newTimeRounded !== undefined &&
            newTimeRounded !== null &&
            // newTimeRounded > 1.0 &&
            (!this.lastTimeSaved ||
                Math.abs(newTimeRounded - this.lastTimeSaved) > 5.0 || // +/- 5 seconds
                newTimeRounded === 0 && newTimeRounded < this.lastTimeSaved || // user scrubbed back to the beginning of the video
                force)
        ) {
            this.lastTimeSaved = newTimeRounded;
            this.appData.addOrUpdateWatchedVideo(
                this.videoInfo._id,
                newTimeRounded,
                expiryDate,
                dur,
                fin,
                this.clip?._id
            );
            this.logger.debug(`Updated WatchedVideo: With time ${newTimeRounded}`);
        } else {
            // this.logger.debug(
            //     `Updated WatchedVideo: Not updating watched video time ${newTimeRounded}`
            // );
        }
    }

    async favorVideo() {
        if (!this.videoInfo) {
            return;
        }
        try {
            const response = await this.appData.addOrUpdateFavoredVideo(
                this.videoInfo._id,
                new Date(this.videoInfo.expiryDate)
            );
            this.logger.debug('favorVideo response', response);
            this.favoredVideo = response || null;
            this.translate.get('video_was_added_to_favorites').subscribe(text => {
                this.uiUtils.displayToast(text);
            });
            const video = this.appData.videos?.find((v) => v._id === this.videoInfo._id);
            if (video) {
                video.favored = true;
            }
            this.analytics.trackAnalyticsEvent(
                AnalyticsCategory.FavoredVideos,
                'favor_video',
                this.videoInfo.cat_id
            );
        } catch (err) {
            this.logger.error('Error favoring video', err);
        }
    }

    async unfavorVideo() {
        if (!this.videoInfo) {
            return;
        }
        try {
            const response = await this.appData.deleteFavoredVideo(this.videoInfo._id);
            this.logger.debug('unfavorVideo response', response);
            this.favoredVideo = null;
            const text = await this.translate.get('video_was_removed_from_favorites').toPromise();
            this.uiUtils.displayToast(text);
            const video = this.appData.videos?.find((v) => v._id === this.videoInfo._id);
            if (video) {
                video.favored = false;
            }
            this.analytics.trackAnalyticsEvent(
                AnalyticsCategory.FavoredVideos,
                'unfavor_video',
                this.videoInfo.cat_id
            );
        } catch (err) {
            this.logger.error('Error un-favoring video', err);
        }
    }

    // ### Intro animation methods

    introStartButtonClicked(startVideo: boolean) {
        this.logger.debug('introStartButtonClicked');
        this.introAnimation?.hide();
        this.destroyAllPopovers();
        if (startVideo) {
            // this.videoJSplayer.play();
            this.playVideo();
        }
    }

    /**
     * Starts the intro animation where we automatically click words from the subtitle.
     */
    startIntroAnimation() {
        this.introAnimation.animationLoopSubscription = interval(4500).subscribe(() => {
            this.logger.debug(`Animation loop - index: ${JSON.stringify(this.introAnimation.subtitleIndex)}`);
            if (!this.introAnimation.subtitleIndex) {
                return;
            }
            if (this.settingsOverlayShown) {
                return;
            }
            const wordIndex = this.introAnimation.subtitleIndex.wordIndex;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const element = document.querySelector(`.drop-target-${wordIndex}`) as HTMLElement;
            if (element) {
                this.logger.debug('Clicking on element', wordIndex, element);
                // element.onmousedown(null);
                // element.onmouseup(null);
                this.moveAndClickIntroCursor(element);
            } else {
                this.logger.debug('Clicking on element not possible, is null', wordIndex);
            }
        });
    }

    /**
     * Move the "fake cursor" element to a certain element in the DOM and click it.
     * @param element The HTML element which you want to move the cursor to and click on (onmousedown, onmouseup)
     */
    moveAndClickIntroCursor(element: HTMLElement) {
        // console.log('randomWordClick: got element', element.textContent);
        // console.dir(element);
        const textLeft = window.pageXOffset + element.getBoundingClientRect().left;
        const textTop = window.pageYOffset + element.getBoundingClientRect().top;
        const textWidth = element.getBoundingClientRect().width;
        const textHeight = element.getBoundingClientRect().height;

        // console.log(`textLeft: ${textLeft}, textTop: ${textTop}, textWidth: ${textWidth}, textHeight: ${textHeight}`);

        const mainVideoEl = document.getElementById('main_video');
        const videoLeft = window.pageXOffset + mainVideoEl.getBoundingClientRect().left;
        const videoTop = window.pageYOffset + mainVideoEl.getBoundingClientRect().top;
        // console.log(`videoLeft: ${videoLeft}, videoTop: ${videoTop}`);

        let left = textLeft - videoLeft;
        let top = textTop - videoTop;
        // console.log(`left: ${left}, top: ${top}`);

        left = left + (textWidth * 0.4 + textWidth * 0.2 * Math.random()) - 10;
        top += textHeight * (0.5 + 0.4 * Math.random());

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const clickCursorEl = document.getElementById('click-cursor') as HTMLElement;
        // clickCursorEl.style.transition = 'transition: left 1s cubic-bezier(0, 0, 1, 1);'

        const animationDurationMs = 900;
        clickCursorEl.style.transition = `all ${animationDurationMs}ms ease`;
        clickCursorEl.style.left = `${left}px`;
        clickCursorEl.style.top = `${top}px`;
        clickCursorEl.style.display = 'initial';


        setTimeout(() => {
            // element.onclick();
            element.onmousedown(null);
            element.onmouseup(null);
        }, animationDurationMs + 100);
        setTimeout(() => {
            clickCursorEl.style.transition = 'none';
            clickCursorEl.style.width = '18px';
        }, animationDurationMs + 50);
        setTimeout(() => {
            clickCursorEl.style.width = null;
        }, animationDurationMs + 150);
    }


    findSubtitleForIntroAnimation(translation: ITranslation): {chunkIndex: number; wordIndex: number;} {
        this.logger.debug('translation', translation);
        let subtitle = translation.subtitles.find((sub, index) => {
            if (sub.fromTok) {
                if (index > 1 && sub.fromTok.length >= 6 || index > 5 && sub.fromTok.length >= 4) {
                    return true;
                }
            } else {
                if (index > 1 && sub.fromWords.length >= 6 || index > 5 && sub.fromWords.length >= 4) {
                    return true;
                }
            }
            return false;
        });
        if (!subtitle) {
            subtitle = translation.subtitles[0];
        }
        let wordIndex = -1;
        let minLength = 7;
        while (wordIndex === -1) {
            wordIndex = this.findRandomFromWordIndex(subtitle, minLength);
            --minLength;
        }

        return {
            chunkIndex: translation.subtitles.indexOf(subtitle),
            wordIndex
        };
    }

    findRandomFromWordIndex(subtitle: ITranslationSubtitles, minLength: number): number {
        let wordIndex = -1;
        while (wordIndex === -1) {
            const fromWordsLength = subtitle.fromTok ? subtitle.fromTok.length : subtitle.fromWords.length;
            const idx = Math.floor(Math.random() * fromWordsLength);
            const word = subtitle.fromTok ? subtitle.fromTok[idx].text : subtitle.fromWords[idx];
            if (word.length >= minLength) {
                wordIndex = idx;
            }
        }
        return wordIndex;
    }

    hidePostroll() {
        this.logger.debug('hiding postroll');
        this.showPostroll = false;
    }

    lastWordsClicked(num = 6): IWordCatalog[] {
        const takeNumber = num;
        // if (!takeNumber) {
        //     if (this.plt.is('mobile')) {
        //         takeNumber = 5;
        //     } else {
        //         takeNumber = 7;
        //     }
        // }
        return _
            .chain(this.wordsClicked)
            .uniqBy('fromWord')
            .takeRight(takeNumber)
            .value();
    }

    /**
     * Set time to 0 and play the video.
     */
    replayVideoFromStart() {
        this.videoJSplayer.currentTime(0);
        this.videoJSplayer.play();
    }

    /**
     * Keep the phone's screen awake.
     *
     * @param source source of the call, just for logging.
     */
    private async keepAwake(source?: string) {
        if (this.plt.is('cordova') || this.plt.is('capacitor')) {
            try {
                await KeepAwake.keepAwake();
                this.logger.debug('insomnia keepAwake success');
            } catch (err) {
                this.logger.warn(`insomnia keepAwake error (${source})`, err);
            }
        } else {
            this.logger.debug(`insomnia keepAwake - not cordova|capacitor (${source})`);
        }
    }

    /**
     * Let the phone's screen sleep again.
     *
     * @param source source of the call, just for logging.
     */
    private async allowSleepAgain(source?: string) {
        if (this.plt.is('cordova') || this.plt.is('capacitor')) {
            try {
                await KeepAwake.allowSleep();
                this.logger.debug('insomnia allowSleepAgain success');
            } catch (err) {
                this.logger.warn(`insomnia allowSleepAgain error (${source})`, err);
            }
        } else {
            this.logger.debug(`insomnia allowSleepAgain - not cordova|capacitor (${source})`);
        }
    }

    /**
     * Opens worksheet select page for specific video
     *
     * @param video The video for which a worksheet will be selected
     */
    openWorksheetSelectPage() {
        const navigationExtras: NavigationExtras = {
            state: {
                video: this.videoInfo
            }
        };
        this.navCtrl.navigateForward('worksheet-select', navigationExtras);
    }

    onWordClicked(
        chunk: ITranslationSubtitles,
        oriWord_index: number,
        subtitlesIndex: number
    ) {
        const clickDelay = this.videoClickDelay;
        this.logger.debug('clickDelay', clickDelay);
        if (clickDelay === 9999) {
            this.videoJSplayer.pause();
            this.clickDelay.isPausedInfinite = true;
        } else if (
            clickDelay > 0 &&
            !(this.videoJSplayer.paused() && !this.clickDelay.timeout) // Don't pause if user paused "manually"
        ) {
            if (this.clickDelay.timeout) {
                clearTimeout(this.clickDelay.timeout);
            }
            this.videoJSplayer.pause();
            const percentUpdater = interval(100).subscribe(() => {
                this.calculateClickDelayCountdownPercent();
            });
            this.clickDelay.timeout = setTimeout(() => {
                this.clickDelay.timeoutStart = null;
                this.clickDelay.timeoutEnd = null;
                this.videoJSplayer.play();
                setTimeout(() => {
                    this.clickDelay.timeout = null;
                    percentUpdater.unsubscribe();
                }, 100);
            }, clickDelay * 1000);
            this.clickDelay.timeoutStart = moment();
            this.clickDelay.timeoutEnd = moment().add(clickDelay, 'seconds');
            this.calculateClickDelayCountdownPercent();
        }
    }

    calculateClickDelayCountdownPercent(): { countdownPercent: number; showProgressBar: boolean; } {
        // this.logger.debug('clickDelayCountdownPercent', this.clickDelayTimeoutEnd, this.clickDelayTimeoutStart);
        if (!this.clickDelay.timeoutEnd || !this.clickDelay.timeoutStart) {
            this.clickDelay.countdownPercent = 100;
            this.clickDelay.showProgressBar = false;
        } else {
            const totalMs = this.clickDelay.timeoutEnd.diff(
                this.clickDelay.timeoutStart,
                'milliseconds'
            );
            const expiredMs = moment().diff(this.clickDelay.timeoutStart, 'milliseconds');
            const percent = 1.0 - expiredMs / totalMs;
            this.logger.debug('clickDelayCountdownPercent', percent);
            this.clickDelay.countdownPercent = percent;
            this.clickDelay.showProgressBar = percent > 0;
        }
        return {
            countdownPercent: this.clickDelay.countdownPercent,
            showProgressBar: this.clickDelay.showProgressBar,
        };
    }

    /**
     * Shows the element with the new value of the setting for 1.5 seconds.
     *
     * @param element the DOM element to show (class="video-overlay-button-value")
     */
    showTopOverlayButtonValue(element: Element) {
        if (element['overlay_button_value_timeout']) {
            clearTimeout(element['overlay_button_value_timeout']);
        }
        element.classList.add('video-overlay-button-value-shown');
        const timeout = setTimeout(() => {
            element.classList.remove('video-overlay-button-value-shown');
        }, 1500);
        element['overlay_button_value_timeout'] = timeout; // Hack: attach property to DOM element
    }

    get isLocked(): boolean {
        return this.screenLockComponent?.isLocked || false;
    }

    /**
     * Callback for the VideoPlayerScreenLock component.
     * @param progress the progress of the screen lock animation (between 0 and 1)
     */
    onScreenLockProgress(progress: number): void {
        this.logger.debug('screen lock progress', progress);
        this.videoJSplayer.userActive(true);
    }

    /**
     * @returns true if only the video player should be shown (no info area)
     */
    isShowPlayerOnly(): boolean {
        if (this.isLocked) {
            return true;
        }
        if (this.isMobileLandscape() && this.isIOsWeb) {
            return true;
        }
        return false;
    }

    /**
     * Returns the size of the first and second column on an xl screen.
     * See <https://ionicframework.com/docs/layout/grid#default-breakpoints>
     */
    get columnSizeXl(): number[] {
        // On a tablet, always make it full width
        return this.plt.is('tablet') ? [12, 12] : [9, 3];
    }

    // ### TASKS

    checkTaskAtCurrentTime() {
        if (
            // this.appData.isEducator() ||
            _.isNil(this.videoPlayerTaskOverlay) ||
            this.videoPlayerTaskOverlay.isDone() ||
            this.videoPlayerEducatorOverlay.isOverlayShown
        ) {
            return;
        }
        const time = this.currentTime + this.videoOffsetStart;
        let tasks = this.videoPlayerTaskOverlay.tasks;
        // Check if user is an educator and whether assigned tasks have been loaded or assigned tasks length > 0
        // Assigned tasks is initialized as an empty array, they won't be loaded if the user hasn't saved the video as a clip yet,
        // so we also need to check for assignedTasks.length > 0
        if (
            this.appData.isEducator() &&
            (this.videoPlayerEducatorOverlay?.isAssignedTasksLoaded ||
                this.videoPlayerEducatorOverlay.assignedTasks?.length > 0)
        ) {
            tasks = this.videoPlayerEducatorOverlay.assignedTasks;
        }
        const tasksAtCurrentTime = tasks?.filter((task) => {
            const deltaSec = time - task.position;
            // Find all tasks within the last 0.5 sec
            return (
                deltaSec > 0 &&
                deltaSec < 0.5 &&
                !this.videoPlayerTaskOverlay.shownTaskIds.includes(task._id)
            );
        });
        if (
            tasksAtCurrentTime &&
            tasksAtCurrentTime.length > 0 &&
            !this.videoPlayerEducatorOverlay.isOverlayShown
        ) {
            const firstTask = tasksAtCurrentTime[0];
            tasksAtCurrentTime.shift();
            this.videoPlayerTaskOverlay.initNewTask(firstTask, tasksAtCurrentTime);
            // this.videoPlayerTaskOverlay.shownTaskIds.push(firstTask._id);
            this.videoPlayerTaskOverlay.shownTaskIds = _.concat(
                this.videoPlayerTaskOverlay.shownTaskIds,
                firstTask._id,
                tasksAtCurrentTime.map((t) => t._id)
            );
            this.pauseVideo();
            this.logger.info(
                `Found task ${firstTask.title} (${firstTask._id}) + ${tasksAtCurrentTime.length} more at current time ${time}`
            );
        }
    }

    /**
     * Adds a set of markers to the video progress bar.
     * Inspiration from: https://codepen.io/nickwanhere/pen/gOMderr
     * Note: "time" is the time in seconds of the position in the VIDEO (not in the CLIP)
     * Time might be 20 sec., but the clip starts at 5 sec., then the marker needs to be shown at 15 sec.
     * @param markers the markers
     */
    addMarkersToProgressBar(markers: { time: number; label: string; id: string; }[]) {
        this.logger.debug('addMarkersForTasks', markers.length);

        // const markers = [
        //     { time: 50, label: 'Hello' },
        //     { time: 150, label: 'Hello asd a asd asdsad' },
        //     { time: 200, label: 'Hello' },
        //     { time: 220, label: 'Hello' },
        // ];

        const total = this.videoJSplayer.duration();

        const p = this.videoJSplayer.controlBar.progressControl.children_[0].el_;
        this.logger.debug('addMarkersForTasks', total, p);

        for (let i = 0; i < markers.length; i++) {
            const marker = markers[i];
            const timeWithOffset = marker.time - this.videoOffsetStart;
            const leftPercent = timeWithOffset / total * 100;

            if (leftPercent >= 0 && leftPercent <= 100) {
                const left = `${leftPercent}%`;

                const div = document.createElement('div');
                div.setAttribute('class', `vjs-marker vjs-marker-${markers[i].id}`);
                div.setAttribute('style', `left: ${left}`);
                div.setAttribute('data-time', `${timeWithOffset}`);

                const span = document.createElement('span');
                span.innerHTML = markers[i].label;
                div.append(span);

                div.onclick = (event: any) => {
                    // console.dir(event.target.parentNode.attributes['data-time'].value);
                    // this.videoJSplayer.currentTime(event.target.parentNode.attributes['data-time'].value);
                    this.videoJSplayer.currentTime(timeWithOffset);
                };

                p.append(div);
            } else {
                this.logger.debug(`addMarkersToProgressBar() marker '${JSON.stringify(markers[i])}' is out of bounds (${leftPercent}%), start offset: ${this.videoOffsetStart}`);
            }
        }
    }

    removeAllMarkersFromProgressBar() {
        const elements = document.getElementsByClassName('vjs-marker');
        while (elements.length > 0) {
            elements[0].remove();
        }
    }

    /**
     * Removes the CSS class VjsMarkerClass.disabled and VjsMarkerClass.highlight from all
     * elements with class 'vjs-marker'.
     */
    resetVjsMarkerClassForAllMarkers() {
        const markerElements = document.getElementsByClassName(`vjs-marker`);
        for (let i = 0; i < markerElements.length; i++) {
            const markerElement = markerElements[i];
            markerElement.classList.remove(VjsMarkerClass.disabled);
            markerElement.classList.remove(VjsMarkerClass.highlight);
        }
    }

    resetShownTasks() {
        this.logger.debug('resetShownTasks');
        this.videoPlayerTaskOverlay.shownTaskIds = [];
        this.resetVjsMarkerClassForAllMarkers();
    }

    /**
     * Sets the indicators in the progress bar for the clipping.
     *
     * @param startTime start time of the clip in seconds
     * @param endTime end time of the clip in seconds
     * @param cropStartTime crop start time of the clip in seconds
     * @param cropEndTime crop end time of the clip in seconds
     */
    setClipMarkers(startTime: number, endTime: number, cropStartTime: number, cropEndTime: number): void {

        let startSeconds = startTime;
        if (!_.isNil(cropStartTime)) {
            startSeconds = startTime - cropStartTime;
        }

        let endSeconds = endTime;
        if (!_.isNil(cropEndTime)) {
            endSeconds = endTime - cropStartTime;
        }

        this.logger.debug(`setClipMarker original: ${startTime} ${endTime} / with crop: ${startSeconds} ${endSeconds}`);

        const total = this.videoJSplayer.duration();

        this.removeClipMarkers();

        // This is the .vjs-progress-holder
        const p = this.videoJSplayer.controlBar.progressControl.children_[0].el_;

        // This is the .vjs-progress-control
        // const p = this.videoJSplayer.controlBar.progressControl.el_;

        // Start marker
        const startPercent = `${100 - startSeconds / total * 100}%`;
        const divStart = document.createElement('div');
        divStart.setAttribute('class', `vjs-clip-marker vjs-clip-marker-start`);
        divStart.setAttribute('style', `left: 0px; right: ${startPercent};`);
        const spanStart = document.createElement('span');
        divStart.append(spanStart);
        p.append(divStart);

        // End marker
        const endPercent = `${endSeconds / total * 100}%`;
        const divEnd = document.createElement('div');
        divEnd.setAttribute('class', `vjs-clip-marker vjs-clip-marker-end`);
        divEnd.setAttribute('style', `left: ${endPercent}; right: 0px;`);
        const spanEnd = document.createElement('span');
        divEnd.append(spanEnd);
        p.append(divEnd);
    }

    removeClipMarkers(): void {
        const elements = document.getElementsByClassName('vjs-clip-marker');
        while (elements.length > 0) {
            elements[0].remove();
        }
    }

    async generateLeaderboardForClip() {
        if (!this.clip) {
            this.logger.debug('generateLeaderboardForClip() - no clip');
            return;
        }
        const generate = async () => {
            const leaderboardResponse = await this.clipsApi.generateLeaderboard(this.clip._id).toPromise();
            this.clip.leaderboard = leaderboardResponse.data.leaderboard;
            this.uiUtils.displayToast(leaderboardResponse.msg);
            this.showLeaderboardOverlay();
        };

        if (this.clip.leaderboard) {
            // Show confirmation message
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('confirm_regenerate_leaderboard_header'),
                message: this.translate.instant('confirm_regenerate_leaderboard_message'),
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel'
                    },
                    {
                        text: this.translate.instant('btn_yes'),
                        handler: async () => {
                            await generate();
                        }
                    }
                ]
            });
            await alert.present();
        } else {
            generate();
        }
    }

    async showLeaderboardOverlay() {
        if (!this.clip) {
            this.logger.debug('showLeaderboard() - no clip');
            return;
        }
        const isPaused = this.videoJSplayer.paused();
        if (!isPaused) {
            this.videoJSplayer.pause();
        }
        await this.checkLandscapeFullscreen();
        this.leaderboardOverlayShown = true;
        this.videoPlayerLeaderboardOverlay.onShow();
    }

    async onCloseLeaderboardOverlay() {
        this.leaderboardOverlayShown = false;
        this.videoPlayerLeaderboardOverlay.onClose();
    }

    // ### KEYBOARD EVENTS

    @HostListener('document:keydown', ['$event'])
    handleKeydownEvent(event: KeyboardEvent) {
        // this.logger.debug('handleKeydownEvent', event);
        // We need to check whether any dialogs, modals, etc. are open - if yes, ignore the keydown event
        Promise.all([
            this.alertCtrl.getTop(),
            this.modalController.getTop(),
            this.popoverCtrl.getTop(),
        ]).then(([isAlertOpen, isModalOpen, isPopoverOpen]) => {
            this.logger.debug(`isAlertOpened=${isAlertOpen}, isModalOpened=${isModalOpen}, isPopoverOpen=${isPopoverOpen}`);
            if (isAlertOpen || isModalOpen || isPopoverOpen || this.settingsOverlayShown) {
                return;
            }
            switch (event.code) {
                case 'Space':
                    if (!this.videoPlayerTaskOverlay.isShown && !this.videoPlayerEducatorOverlay.isOverlayShown) {
                        this.videoJSplayer.paused()
                            ? this.videoJSplayer.play()
                            : this.videoJSplayer.pause();
                    }
                    break;
                case 'ArrowLeft':
                case 'ArrowRight':
                    if (!this.videoPlayerTaskOverlay.isShown && !this.videoPlayerEducatorOverlay.isTimeInputFieldFocused) {
                        this.skipVideo(event.code === 'ArrowLeft' ? -10 : +10, 'keydown');
                    }
                    break;

                case 'ArrowUp':
                case 'ArrowDown': {
                    let newVolume;
                    if (event.code === 'ArrowUp') {
                        newVolume = (Math.ceil(this.videoJSplayer.volume() * 10) + 1) / 10; // Round to 10% steps
                    } else {
                        newVolume = (Math.floor(this.videoJSplayer.volume() * 10) - 1) / 10; // Round to 10% steps
                    }
                    this.videoJSplayer.volume(newVolume);
                    this.uiUtils.displayToast(
                        `${this.translate.instant('volume')}: ${Math.round(
                            this.videoJSplayer.volume() * 100
                        )} %`,
                        undefined,
                        1000
                    );
                    break;
                }
                default:
                    break;
            }

        });
    }
}
