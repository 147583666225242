// angular
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

// ionic
import { NavController, IonContent, AlertController, IonInfiniteScroll, Platform } from '@ionic/angular';

// models
import { SavedWord } from '../../models/saved-word.model';
import { IGroup } from 'src/app/interfaces/IGroup';
import { WordCatalogListResponse } from '../../models/dtos';

// services
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';

// libraries
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { WordCatalogApi } from 'src/app/services/api/wordcatalog.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';
import { Constants } from '../../app.constants';
import { Utils } from '../../utils/utils';
import { ILanguages } from '../../interfaces/ILanguages';

@Component({
    selector: 'app-word-catalog',
    templateUrl: 'word-catalog-page.html',
    styleUrls: ['word-catalog-page.scss']
})
export class WordCatalogPage implements OnInit, OnDestroy {
    @ViewChild(IonContent, { static: false }) content: IonContent;
    @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
    wordCatalog: Array<SavedWord|IWordCatalog> = null;
    class_id: string;
    class: IGroup;
    offset = 0;
    readonly PAGE_SIZE = 50;
    expandedIndices: Set<number> = new Set();

    constructor(private _route: ActivatedRoute,
        public navCtrl: NavController,
        public appData: AppData,
        public translate: TranslateService,
        public analytics: AnalyticsService,
        public alertCtrl: AlertController,
        public wordCatalogApi: WordCatalogApi,
        public uiUtils: UiUtils,
        private constants: Constants,
        private titleService: Title,
        private logger: NGXLogger,
        public plt: Platform) {
        // Doing nothing.
    }

    ionViewWillEnter() {
        this.logger.debug('ionViewWillEnter');
        this.class_id = this._route.snapshot.params.class_id;
        if (this.class_id) {
            // this.appData.activePage = `word-catalog/${this.class_id}`;
            // this.appData.activeGroupId = this.class_id;
            this.appData.activePage = 'word-catalog-groups';
        } else {
            this.appData.activePage = 'word-catalog';
        }

        // this.logger.debug('WordCatalogPage _route.data', this._route.data);
        // this.logger.debug('WordCatalogPage _route.snapshot.data: ', this._route.snapshot.data);

        this._route.snapshot.data.resData.subscribe(data => {
            // this.logger.debug('WordCatalogPage resData: ', data);
            if (data) {
                this.wordCatalog = data.wordCatalog;
                this.class = data.group;
                this.offset = this.wordCatalog.length;
            } else {
                console.log('WordCatalogPage got no data');
            }
        });

        // Disable infinite scrolling if user is not logged in
        this.infiniteScroll.disabled = !this.appData.authenticatedUser;
    }

    ngOnInit() {
        console.log('ngOnInit');
        this.titleService.setTitle('Word Catalog | uugot.it');
        this.analytics.trackPageView('word-catalog');

        // Text is showing as variables without the timeout if link is accessed directly
        setTimeout(() => {
            if (this.constants.SaveWordCatalogLocally) {
                this.checkIfShowPremiumAlert();
            }
        }, 1000);

        // Not being used
        // Check if we need to scroll to the bottom
        // if (this.navParams.get('scrollToBottom')) {
        //     setTimeout(() => {
        //         this.content.scrollToBottom(0);
        //     }, 100);
        // }
    }

    ionViewDidLeave() {
        console.log('ionViewDidLeave');
        // this.wordCatalog = [];
    }

    ngOnDestroy() {
        console.log('ngOnDestroy');
        // this.wordCatalog = [];
    }

    checkIfShowPremiumAlert() {
        if (!this.appData.isLoggedIn() && this.wordCatalog && this.wordCatalog.length >= 20) {
            this.showAlertPremium();
        }
    }

    /**
     * Loads word catalog from the server
     *
     * @param offset - offset for paging
     * @param limit - Number of words to retrieve per call
     */
    loadWordCatalog(limit: number, offset: number, onComplete: () => void) {
        let wordCatalogResonse: Observable<WordCatalogListResponse>;
        if (this.isUseClassId) {
            wordCatalogResonse = this.wordCatalogApi.getClassCatalog(this.class_id, limit, offset);
        } else if (this.appData.authenticatedUser) {
            wordCatalogResonse = this.wordCatalogApi.getCatalog(limit, offset);
        } else {
            wordCatalogResonse = this.wordCatalogApi.getCatalog4Public(this.appData.getInstallationId(), limit, offset);
        }

        wordCatalogResonse.subscribe(response => {
            // Append new words to word catalog array
            if (!this.wordCatalog) {
                this.wordCatalog = [];
            }
            // eslint-disable-next-line prefer-spread
            this.wordCatalog.push.apply(this.wordCatalog, response.data);

            // Update current offset
            this.offset += response.data.length;

            // Disable infinite scrolling if all words loaded
            this.infiniteScroll.disabled = this.offset >= response.count;

            this.checkIfShowPremiumAlert();
        }, err => {
            console.log(err);
            this.uiUtils.showErrorAlert(err.message || err.msg);
        }, () => {
            onComplete();
        });
    }

    /**
     * Displays premium functionality alert
     */
    async showAlertPremium() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('words_catalog_premium_title'),
            subHeader: this.translate.instant('words_catalog_premium_subtitle'),
            buttons: [
                // {
                //     text: this.translate.instant('btn_cancel'),
                //     role: 'cancel'
                // },
                // {
                //     text: this.translate.instant('button_log_in'),
                //     handler: () => {
                //         this.navCtrl.navigateRoot('login');
                //     }
                // }
                {
                    text: this.translate.instant('btn_ok'),
                    role: 'cancel'
                },
            ]
        });
        await alert.present();
    }

    /**
     * Opens word catalog cards page
     *
     * @param videoId The video ID that contains the word
     * @param index Index of the word in the word catalog
     */
    openWordCatalogCardsPage(videoId: string, index: number) {
        this.analytics.trackAnalyticsEvent(AnalyticsCategory.WordCatalog, 'show_card', videoId);

        const navigationExtras: NavigationExtras = {
            state: {
                index,
                wordCatalog: this.wordCatalog,
                group: this.class
            }
        };

        const path = this.class_id ? `word-catalog-cards/${this.class_id}` : 'word-catalog-cards';
        this.navCtrl.navigateForward([path], navigationExtras);
    }

    // onCheck(word: SavedWord) {
    //     console.log('onCheck ' + word);
    //     word.selected = !word.selected;
    // }

    /**
     * Gets selected words by the user
     *
     * @returns Returns an array of selected saved words
     */
    getSelected(): Array<SavedWord|IWordCatalog> {
        if (!this.wordCatalog) {
            return [];
        }
        return this.wordCatalog.filter((item) => item.selected);
    }

    /**
     * Deletes selected words from the word catalog
     */
    deleteSelected() {
        if (this.appData.isLoggedIn() || !this.constants.SaveWordCatalogLocally) {
            const wordIds = this.wordCatalog.filter(word => word.selected).map(word => word._id);
            this.wordCatalogApi.deleteWordsById(wordIds).subscribe(response => {
                console.log(response);
                this.offset -= wordIds.length;
                this.wordCatalog = this.wordCatalog.filter((item) => !item.selected);
            }, err => {
                console.log(err);
                this.uiUtils.showErrorAlert(err.message || err.msg);
            });
        } else {
            this.wordCatalog = this.wordCatalog.filter((item) => !item.selected);
            this.appData.saveWordCatalog(this.wordCatalog as SavedWord[]);
        }
    }

    /**
     * Deletes all words from the word catalog
     */
    async deleteAll() {
        // Show confirmation alert
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('remove_all_words'),
            buttons: [
                {
                    text: this.translate.instant('btn_yes'),
                    handler: () => {
                        if (this.isUseClassId) {
                            this.wordCatalogApi.deleteClassCatalog(this.class_id).subscribe(response => {
                                console.log(response);
                                this.wordCatalog = [];
                            }, err => {
                                console.log(err);
                                this.uiUtils.showErrorAlert(err.message || err.msg);
                            });
                        } else if (this.appData.authenticatedUser) {
                            this.wordCatalogApi.deleteCatalog().subscribe(response => {
                                console.log(response);
                                this.wordCatalog = [];
                                this.infiniteScroll.disabled = true;
                            }, err => {
                                console.log(err);
                                this.uiUtils.showErrorAlert(err.message || err.msg);
                            });
                        } else {
                            if (this.constants.SaveWordCatalogLocally) {
                                this.wordCatalog = [];
                                this.appData.saveWordCatalog(this.wordCatalog as SavedWord[]);
                            } else {
                                this.wordCatalogApi.deleteCatalog4Public(this.appData.getInstallationId()).subscribe(response => {
                                    console.log(response);
                                    this.wordCatalog = [];
                                    this.infiniteScroll.disabled = true;
                                }, err => {
                                    console.log(err);
                                    this.uiUtils.showErrorAlert(err.message || err.msg);
                                });
                            }
                        }
                    }
                },
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
            ]
        });
        await alert.present();
    }

    /**
     * Navigates back to the class which the word catalog belongs to
     */
    navigateBack() {
        // this.appData.activePage = '';

        // if (this.appData.authenticatedUser.role === 'student') {
        //     this.navCtrl.navigateBack(`student-class/details/${this.class_id}`);
        // } else {
        //     this.navCtrl.navigateBack(`educator-class/details/${this.class_id}`);
        // }

        this.navCtrl.navigateBack('word-catalog-groups');
    }

    /**
     * Loads next words in catalog using Ion Infinite Scoll
     *
     * @param event Ion infinite scroll event
     */
    doInfinite(event: any) {
        console.log('doInfinite', event);
        this.loadWordCatalog(this.PAGE_SIZE, this.offset, () => {
            console.log('Async infinite scroll reload operation has ended');
            event.target.complete();
        });
    }

    checkSplitWordLength(toWord: string, toLanguage: ILanguages) {
        const join = Utils.splitWordIntoLinesJoin(toWord, toLanguage, '<br>');
        const elements = join.split('<br>');
        return elements.length > 3;
    }

    splitWordIntoLinesJoinMaxed(toWord: string, toLanguage: ILanguages) {
        return Utils.splitWordIntoLinesJoinMaxed(toWord, toLanguage, '<br>');
    }

    toggleExpand(index: number) {
    if (this.expandedIndices.has(index)) {
        this.expandedIndices.delete(index);
    } else {
        this.expandedIndices.add(index);
    }
    }

    isExpanded(index: number): boolean {
    return this.expandedIndices.has(index);
    }

    splitWordIntoLinesJoin(toWord: string, toLanguage: ILanguages) {
        return Utils.splitWordIntoLinesJoin(toWord, toLanguage, '<br>');
    }

    get isUseClassId(): boolean {
        return this.class_id && this.class_id !== this.constants.GeneralClassId;
    }
}
